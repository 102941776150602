.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.bodyContent {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg px-6 pt-6 py-4 overflow-hidden;
}

.formChangePassword {
  @apply gap-y-4 flex flex-col;
}

.form {
  @apply w-1/3 gap-y-6 flex flex-col;
}

.groupButton {
  @apply flex flex-row items-center justify-end gap-3;
}
