.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.table {
  @apply flex-1 overflow-y-auto;
}

.table::-webkit-scrollbar {
  @apply hidden;
}

.pagination {
  @apply mt-4;
}

.header {
  @apply flex items-center justify-end mb-4;
}
