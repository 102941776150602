.iconCloseModal {
  @apply absolute top-[10px] right-[10px] cursor-pointer;
}

.header {
  @apply text-20-700-normal mb-[20px];
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

// Scroll bar
.body::-webkit-scrollbar {
  @apply w-1;
}

.body::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.body::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

// Actions
.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}
