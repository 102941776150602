.container {
  @apply w-full flex justify-between gap-[10px];
}

.fieldContent {
  @apply flex-1 gap-x-5 grid grid-cols-8;
}

.colItem {
  @apply col-span-3;
}

.colValue {
  @apply col-span-5 justify-self-end;
}

.btnRemoveContainer {
  @apply flex items-center justify-center mt-6;
}

.btnRemoveField {
  @apply w-4 h-4;
}
