.modalContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px] font-inter;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.form {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.body {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-5;
}

.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}
