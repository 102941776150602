.container {
  @apply flex gap-2 flex-row-reverse items-center justify-end;
}

// Label
.label {
  @apply text-12-400-normal text-primary-neutral-400 truncate capitalize select-none cursor-pointer;
}

// Checkbox
.mainCheckbox {
  @apply block size-[15px] rounded border border-primary-zinc-500 bg-white outline-none select-none cursor-pointer;
  @apply data-[checked]:bg-primary-cyan-600 data-[checked]:border-none;
  @apply transition ease-linear duration-200;
}

.checkSvg {
  @apply stroke-white opacity-0 group-data-[checked]:opacity-100 transition;
}

.disabledStyle {
  @apply cursor-not-allowed opacity-70;
}
