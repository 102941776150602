.headerContainer {
  @apply flex justify-between items-center pr-8 w-full min-h-[92px] bg-white border-primary-gray-300 border-b-[1px] border-l-[1px] sticky top-0 z-10;
}

.headerRight {
  @apply flex-1 flex justify-end items-center gap-6;
}

.headerIconGroup {
  @apply flex-1 flex justify-end items-center gap-4;
}

.line {
  @apply w-[1px] bg-primary-zinc-500 bg-opacity-50 h-[36px];
}

.notifications {
  @apply transition ease-in-out duration-200 hover:bg-gray-100 rounded-lg;
}
