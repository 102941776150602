.container {
  @apply w-full h-full flex flex-col gap-4 pr-4 overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.bodyScroll {
  @apply w-full h-full pr-1 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.section {
  @apply mb-5;
  @apply last:mb-0;
}
