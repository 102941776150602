.container {
  @apply h-full w-full flex flex-col overflow-hidden pr-[14px];
}

.toolBarWrap {
  @apply my-4 flex items-center justify-between gap-4 pl-6 py-[18px] pr-4 border border-primary-gray-300 rounded-lg;
}

.body {
  @apply border h-full w-full flex flex-col overflow-hidden rounded-lg mr-[14px] p-6;
}

.tabContent {
  @apply w-full pt-5 flex-1 overflow-y-auto;
}

.tabContent::-webkit-scrollbar {
  @apply w-0;
}

.toolbarDesc {
  @apply text-17-600-normal text-primary-cyan-600;
}

.jobDetailsTitle {
  @apply text-17-600-normal text-primary-neutral-700 truncate;
}

.jobDetailsClient {
  @apply flex items-center gap-3;
}

.jobDetailsClientName {
  @apply text-14-400-normal text-primary-neutral-400;
}

.jobDetailsClientValue {
  @apply text-14-500-normal text-primary-cyan-600 truncate cursor-pointer transition ease-in-out duration-300;

  &:hover {
    @apply text-opacity-80;
  }
}

.quickBookWrap {
  @apply flex items-center justify-center;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-full;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-full;
}
