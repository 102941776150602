.container {
  @apply grid grid-cols-[180px_1fr] gap-5 items-center border-b pb-3;
  border-color: rgba(9, 9, 11, 0.05);

  &.noBorder {
    @apply border-b-0 pb-0;
  }
}

.item {
  @apply text-14-400-normal text-wrap;
}

.item.key {
  @apply text-primary-neutral-400 truncate w-fit;

  &.alignSelfStart {
    @apply self-start;
  }
}

.item.val {
  @apply text-primary-zinc-800;

  &.truncateValue {
    @apply truncate;
  }
}

.noteValue {
  @apply line-clamp-3;

  &.expanded {
    @apply line-clamp-none;
  }
}

.toggleButton {
  @apply text-14-400-normal text-primary-cyan-600 mt-2;
}

.tooltipWrap {
  @apply z-10 bg-black flex flex-1 flex-col opacity-100 rounded-[6px] right-7 !important;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-white;
}
