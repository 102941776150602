.container {
  @apply flex flex-col w-full;
}

// Label
.label {
  @apply text-12-400-normal text-primary-zinc-500 truncate capitalize pb-2;
}

// Input
.inputContainer {
  @apply w-full overflow-y-hidden border border-primary-gray-300 pb-2 px-4;
}

.required {
  @apply text-sunset-orange-500;
}
