.mainContainer {
  @apply min-w-[1440px] h-screen flex overflow-x-auto bg-white;
}

.sidebarContainer {
  @apply basis-[293px] h-full p-4;
}

.primaryContent {
  @apply flex-1 flex flex-col overflow-hidden;
}

.content {
  @apply relative mt-6 overflow-hidden mb-[16px] h-full;
}

// Hidden Scrollbar Y
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  @apply w-0 h-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply rounded-lg bg-primary-gray-300;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary-gray-300 rounded-lg;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mainContainer,
.primaryContent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
