.statisticTopComponent {
  @apply flex flex-col h-full overflow-hidden;
}

.headerStatisticTop {
  @apply flex items-center justify-between pb-2;
}

.titleStatisticTop {
  @apply text-17-600-20 text-primary-zinc-800;
}

.content {
  @apply overflow-y-auto flex-1;
}

.content::-webkit-scrollbar {
  @apply hidden;
}

.items {
  @apply mt-4;
}

.noData {
  @apply size-full flex justify-center items-center text-12-400-normal text-primary-neutral-400;
}
