.container {
    @apply w-full;
}
  
.label {
    @apply text-14-500-17 text-primary-neutral-700 truncate mb-2;
}
  
.progress {
    @apply flex-1 relative overflow-hidden;
}
  
.progressBg {
    @apply w-full h-[15px] rounded-full bg-primary-gray-300;
}
  
.progressBar {
    @apply absolute top-0 left-0 bottom-0 bg-primary-cyan-600 rounded-full max-w-full w-0 transition-all ease-linear;
}

.progressPercent {
    @apply absolute top-0 left-[50%] -translate-x-[50%] text-12-400-15 text-primary-zinc-800;
}
  
.progressBar.max {
    @apply bg-green-600;
}
  
.group {
    @apply flex gap-4 items-center;
}
  
.value {
    @apply hidden text-12-400-15 text-cyan-500 truncate;
}
  
.group.loss .value {
    @apply text-rose-600;
}
  
.group.loss .progressBar {
    @apply bg-rose-600;
}
  
.group.sales .value {
    @apply text-cyan-500;
}
  
.group.sales .progressBar {
    @apply bg-cyan-500;
}
  
.container.statistics .label {
    @apply text-black mb-0;
}
  
.container.statistics .value {
    @apply inline-block;
}
  
.container.statistics .progressBg {
    @apply bg-primary-gray-300;
}
  
.labelStatistics {
    @apply hidden;
}
  
.container.statistics .labelStatistics {
    @apply block text-14-400-17 ml-[5px];
}
  
.container.statistics .label {
    @apply hidden;
}
  