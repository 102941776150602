// Button
.btnGroup {
  @apply flex justify-center items-center focus:outline-none;
}

.btnImg {
  @apply h-[20px] w-[20px];
}

.btnLabel {
  @apply text-primary-slate-100 text-12-400-normal;
}

// Popup
.popupContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white w-[240px] border border-primary-neutral-100 p-[20px] rounded-[8px] shadow-[6px_26px_54px_0_rgba(0,16,19,0.1)];
}

.popBtnGroup {
  @apply flex gap-[10px] flex-col;
}

.popIcon {
  @apply w-4 h-4 object-cover;
}

.buttonOption {
  @apply flex gap-2 justify-start items-center w-full h-[29px] p-[6px] rounded-[6px];

  &:hover {
    @apply bg-gray-100;
  }
}

.buttonDelete,
.buttonUpdateStatus {
  @apply flex gap-2 justify-start items-center w-full h-[29px] p-[6px] rounded-[6px];

  &:hover {
    @apply bg-primary-rose-50;
  }
}

.labelText {
  @apply text-primary-zinc-800 text-14-400-17;
}

.labelDelete,
.labelUpdateStatus {
  @apply text-radical-red-500 text-14-400-17;
}
