.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-zinc-500 truncate capitalize;
}

// Input
.inputContainer {
  @apply relative w-full flex items-center justify-between gap-2;
}

.mainInput {
  @apply w-full outline-none px-2 py-[7px] border border-primary-gray-300 rounded-[6px] text-primary-zinc-800 text-12-400-15 truncate;
  @apply placeholder:text-12-400-15 placeholder:text-primary-zinc-500;
  @apply data-[disabled]:bg-primary-stone-50;
}

.mainInput.password {
  @apply pl-2 pr-7;
}

.suffixIconContainer {
  @apply absolute px-2 py-1 right-[1px] top-1/2 -translate-y-1/2 bg-transparent cursor-pointer;
}

.percent {
  @apply cursor-default;
}

.icon {
  @apply w-3 h-3 object-cover;
}

.required {
  @apply text-sunset-orange-500;
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}

// Custom Input Auth
.mainInput.auth {
  @apply outline-0 text-primary-zinc-900 text-14-500-17 py-3 rounded-lg w-full px-5;
}

.mainInput.auth::placeholder {
  @apply text-14-400-17 truncate text-primary-zinc-400;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  @apply appearance-none;
}

.icon.auth {
  @apply w-[22px] h-[22px] cursor-pointer mr-5;
}
