.topStaffItemComponent {
  @apply border-[1px] h-fit border-primary-gray-300 rounded-[4px] p-5;
}

.topStaffItem {
  @apply flex flex-col gap-6;
}

.group {
  @apply flex gap-4 items-start;
}

.images {
  @apply rounded-full object-cover w-[44px] h-[44px];
}

.labelGroup {
  @apply flex flex-col gap-1;
}

.name {
  @apply text-18-600-27 text-primary-woodsmoke-950 font-inter truncate;
}

.rate {
  @apply text-16-600-normal text-primary-woodsmoke-950 truncate font-inter;
}
