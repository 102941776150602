// Form
.formContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px];
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-[20px] pr-2;
}

// Scroll bar
.modalBody::-webkit-scrollbar {
  @apply w-1;
}

.modalBody::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.modalBody::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.modalFooter {
  @apply flex items-center justify-end gap-3 pt-[30px];
}
