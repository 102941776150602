.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.pagePagination {
  @apply mt-6;
}

.poNumber {
  @apply text-red-600 pr-[3px];
}
