.container {
  @apply w-full h-full grid grid-cols-2 gap-6 overflow-y-auto;
}

.container::-webkit-scrollbar {
  @apply w-0;
}

.jobDetailsItem {
  @apply w-full grid grid-cols-[100px_1fr] gap-4;
}

.key {
  @apply text-12-400-normal text-primary-neutral-400 mb-3 last:mb-0 text-wrap;
}

.value {
  @apply text-12-400-normal text-primary-zinc-800 mb-3 last:mb-0 text-wrap;
}
