.container {
  @apply w-full flex flex-col border-primary-gray-300;
}

// Title
.title {
  @apply mb-[12px] text-14-500-normal text-primary-zinc-800;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-5;
}

// Fields
.contentFields {
  @apply w-full flex flex-col items-start justify-start gap-y-5;
}
