.labelGroup {
  @apply py-3;
}

.labelGroup {
  @apply flex items-center relative gap-4 px-8;
}

.menuLink {
  @apply w-full flex items-center justify-between;
}

.menuLink:hover {
  @apply bg-violet-200/10;
}

.menuLabel {
  @apply text-white text-opacity-[50%] text-14-500-17 truncate font-inter;
}

.icon {
  @apply opacity-[50%] w-6 h-6;
}

.iconActive {
  @apply opacity-100;
}

.menuLabelActive {
  @apply text-violet-200 truncate text-14-500-17 font-inter;
}

// Submenu
.submenuWrap {
  @apply w-full;
}

.menuBtn {
  @apply w-full h-[40px] flex items-center justify-between mt-2 pr-[7px];
}

.menuBtn:hover {
  @apply bg-violet-200/10;
}

.submenuContainer {
  @apply pl-[24px] pb-2;
}

.submenuLink {
  @apply w-full h-[28px] flex items-center pl-4 mt-2 first:mt-1;
}

.submenuLink:hover {
  @apply text-primary-cyan-600;
}

.submenuLink:hover .menuLabel {
  @apply text-violet-200;
}

.submenuLinkActive {
  @apply text-primary-cyan-600;
}

.menuLabelActive {
  @apply text-violet-200;
}

.submenuLabelActive {
  @apply text-primary-cyan-600;
}

.iconMenuDropdown {
  @apply rotate-0;
}

.iconMenuDropdownOpen {
  @apply rotate-180;
}
