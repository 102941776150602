.container {
  @apply w-full h-full flex-1 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.billDetailWrap {
  @apply flex gap-10;
}

.billDetail {
  @apply flex-1 flex flex-col gap-4;
}

.itemsWrap,
.totalWrap {
  @apply mt-12;
}

.items {
  @apply flex flex-col gap-4;
}

.titleItem {
  @apply text-14-500-normal text-primary-zinc-800 pb-4 border-b mb-5;
  border-color: rgba(9, 9, 11, 0.05);
}

.itemsHeader {
  @apply grid grid-cols-8 gap-5 border-b pb-3 border-primary-woodsmoke-940/5;
}

.titleItemsHeader {
  @apply col-span-2 text-14-400-normal text-primary-neutral-400;
}

.itemsBody {
  @apply grid gap-5 grid-cols-8 border-b pb-4 border-primary-woodsmoke-940/5;
}

.titleItemsBody {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-2;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.totalWrap {
  @apply grid grid-cols-8;
}

.total {
  @apply flex flex-col gap-4 col-start-6 col-span-3;
}

.poNumber {
  @apply text-radical-red-500 pr-[3px];
}
