.signInContainer {
  @apply w-full h-full flex flex-col items-center justify-center;
}

.logo,
.content {
  @apply w-full;
}

.content {
  @apply flex flex-col;
}

.greeting {
  @apply text-40-700-48 text-primary-zinc-800 mb-[27px] font-inter;
}

.title {
  @apply relative text-20-700-normal text-primary-zinc-800 mb-[44px] font-inter;
}

.title::after {
  @apply content-[''] absolute top-full left-0 mt-[8px] w-[26px] h-1 bg-primary-cyan-600;
}

.form {
  @apply mb-3;
}

.formItem {
  @apply mb-4;
}

.formItem:last-child {
  @apply mb-0;
}

.forgotPassword {
  @apply w-full flex justify-end text-14-500-17 text-primary-zinc-800 mb-8 transition-all font-inter;
}

.forgotPasswordText {
  @apply cursor-pointer;
}

.forgotPassword:hover {
  @apply text-primary-cyan-600;
}
