.container {
  height: 100%;
  min-width: 1000px;
}

// Events
.eventContainer {
  @apply h-full w-full rounded relative border border-solid z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  @apply before:rounded before:border before:border-solid before:border-black;
}

.eventName {
  @apply truncate text-12-400-normal text-primary-zinc-800;
}

.eventTime {
  @apply truncate mt-[2px] text-10-400-normal text-primary-zinc-500;
}

.assigneeContainer {
  @apply mt-auto;
}

// Month
.monthEventWrap {
  @apply max-h-[95px] overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventContainerCollapse {
  @apply px-[10px];
}

.eventContainerExpand {
  @apply p-[6px] flex flex-col h-[95px];
}

// Week
.myScheduleWeekView {
  :global {
    .rbc-day-slot .rbc-event {
      background-color: unset;
      border: unset;
      min-height: 54px;
    }
  }
}

.weekEventWrap {
  @apply size-full;
}

.expandWeekViewContainer {
  @apply flex flex-col p-2;
}

.collapseWeekViewContainer {
  @apply flex flex-wrap gap-[2px] items-center p-1;
}

.collapseWeekViewContainer .assigneeContainer {
  margin-top: unset;
}

.collapseWeekViewContainer .eventName {
  flex-basis: 100%;
}

.collapseDayViewContainer {
  @apply flex flex-row items-center gap-[14px] flex-nowrap p-1;
}

.collapseDayViewContainer .eventName {
  flex-basis: unset;
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}
