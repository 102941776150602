.container {
  @apply w-full h-full flex flex-col gap-5;
}

.containerBody {
  @apply w-full h-full overflow-y-auto border border-primary-gray-300 rounded-lg py-5;
}

.headerForm {
  @apply flex items-center justify-between px-8 py-5 border border-primary-gray-300 rounded-lg z-[1] sticky top-0 bg-white;
}

.viewInfoHeader {
  @apply flex items-center justify-between gap-8;
}

.itemHeader {
  @apply flex items-center gap-3 text-14-400-normal;
}

.titleItemHeader {
  @apply text-primary-neutral-400;
}

.valueItemHeader {
  @apply text-primary-zinc-800;
}

.containerContent {
  @apply w-full;
}

.titleContent {
  @apply text-16-600-normal text-primary-zinc-900 font-inter py-3 border-y border-primary-gray-300 text-center bg-gray-50;
}

.viewTemplate {
  @apply flex h-[50px] items-center justify-end px-8 border-b border-primary-gray-300;
}

.importTemplate {
  @apply text-12-400-normal text-primary-cyan-600;
}

.viewEditor {
  @apply w-full h-[270px] px-4;
}

.containerForm {
  @apply flex flex-col w-full px-8 gap-4 pb-5;
}

.inputRow {
  @apply flex justify-between gap-x-[80px];
}

.viewInput {
  @apply flex flex-1;
}

.label {
  @apply w-[200px] text-12-400-normal text-primary-neutral-400 truncate capitalize;
}

.labelInput {
  @apply mt-[10px];
}

.required {
  @apply text-sunset-orange-500;
}

.viewInfoLineItem {
  @apply flex flex-col w-[50%] px-8 py-4 gap-3;
}

.viewTextInfo {
  @apply flex items-center justify-between;
}

.buttonAction {
  @apply px-8 text-12-400-normal text-primary-cyan-600;
}

.editorDetailsOrComments {
  @apply w-full pl-8;
}

.viewFormLineItem {
  @apply px-8 pt-3 flex flex-col gap-3;
}

.itemAddLineItem {
  @apply flex-1;
}

.itemAction {
  @apply flex items-start justify-between;
}

.inputDescription {
  @apply flex w-[60%] gap-2 items-center;
}

.inputValue {
  @apply w-[30%];
}

.numerical {
  @apply text-12-400-normal text-primary-neutral-400;
}

.checkBox {
  @apply float-end mt-3;
}

.buttonRemoveLineItem {
  @apply h-full;
}

.viewAction {
  @apply flex py-3 items-start;
}

.viewButton {
  @apply w-full flex flex-col gap-3 items-start;
}

.viewActionEdit {
  @apply flex gap-3 items-center;
}

.btnAddField {
  @apply flex items-center gap-x-1 px-8;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.fieldContainer {
  @apply w-full flex justify-between gap-3 px-8 pt-4;
}

.viewBreakdown {
  @apply pl-1;
}

.viewBreakdownOrBill {
  @apply w-full pl-8;
}

.contentBreakdownOrBill {
  @apply gap-2;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.name {
  @apply truncate;
}
