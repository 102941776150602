.container {
  @apply w-full flex flex-col gap-y-4;
}

.header {
  @apply flex items-center gap-3;
}

.title {
  @apply text-16-500-24 text-primary-zinc-800;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1 cursor-pointer;
}

.iconAddField {
  @apply object-cover;
}

.iconAddField {
  @apply w-4 h-4;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.iconAddField {
  @apply w-4 h-4;
}

.iconAddFile {
  @apply w-4 h-4 object-cover;
}

// Css Custom Excel
.customCellRow {
  @apply justify-center !important;
  background-color: rgba(0, 0, 0, 0.04);
}

.customCellDisabled {
  @apply bg-primary-stone-50;
}
