.navTabContainer {
  @apply relative after:content-[''] after:absolute after:h-[1px] after:w-full after:bg-primary-gray-300 after:left-0 after:bottom-0;
}

// Nav link
.navLink {
  @apply inline-flex items-center px-3 pb-[11px];
}

.navLinkActive {
  @apply relative z-[1] border-primary-green-650 border-b-[2px];
}

.textStyle {
  @apply text-14-500-17 text-primary-zinc-500 transition ease-in-out duration-300;

  &:hover {
    @apply text-primary-zinc-800;
  }
}

.textStyleActive {
  @apply text-14-500-17 text-primary-zinc-800;
}

.numberItem {
  @apply flex items-center justify-center w-5 h-5 rounded-full ml-2 bg-primary-gray-300 text-10-400-normal text-primary-gray-300;
}

// START: CustomStyle tabValue
.navLink.tabValue {
  @apply pr-[5px];
}

.navLinkActive.tabValue {
  @apply border-b-[2px];
}

.textStyle.tabValue {
  @apply text-14-400-normal text-primary-gray-400;
}

.textStyleActive.tabValue {
  @apply text-primary-gray-400;
}
// END: CustomStyle tabValue
