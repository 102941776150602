.container {
  @apply flex flex-col gap-2;
}

.titleLabel {
  @apply text-12-400-normal;
}

.boxContent {
  @apply w-full flex flex-col border border-primary-gray-300 py-2 px-4 gap-2;
}

.viewHeader {
  @apply grid grid-cols-6 gap-[20px] text-12-400-normal text-primary-neutral-400 mb-2 text-end;
}

.titleMarkup {
  @apply text-center;
}

.viewItemLinked {
  @apply grid grid-cols-6 gap-[20px] items-center;
}

.viewColSpace {
  @apply col-span-4;
}

.colTow {
  @apply w-full grid  grid-cols-[minmax(80px,_1fr)_minmax(50px,_1fr)]  gap-[20px] items-center;
}

.textTitleItem {
  @apply text-12-400-normal text-primary-neutral-400 col-span-4;
}

.textCostBudget {
  @apply text-12-400-normal text-primary-neutral-400 text-end;
}

.viewNoData {
  @apply w-full h-[150px] content-center text-center;
}

.textNoData {
  @apply text-12-500-normal text-primary-neutral-400;
}
