.container {
  @apply w-full h-full flex flex-col items-center justify-center;
}

.verifyCodeTitle {
  @apply relative text-20-700-normal text-primary-zinc-800 mb-[30px] font-inter;
}

.sendToMail {
  @apply text-14-400-21 text-primary-zinc-800;
}

.verifyCodeTitle::after {
  @apply content-[''] absolute top-full left-0 mt-[8px] w-[26px] h-1 bg-primary-cyan-600;
}

.formVerifyCode {
  @apply flex gap-[10px] mt-4;
}

.inputVerifyCode {
  @apply w-full h-[56px] rounded-lg border border-primary-slate-200  outline-none;
  @apply bg-gray-50 text-primary-zinc-800 text-16-500-24 text-center;
}

.btnWrap {
  @apply mt-[30px];
}

.resendWrap {
  @apply mt-4 text-14-400-21 text-primary-zinc-800;
}

.resendBtn {
  @apply text-14-600-21 ml-1 cursor-pointer;
  @apply hover:text-primary-cyan-600;
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
