.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply flex relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[780px] max-h-[80%] bg-white p-[30px] rounded-lg overflow-visible;
}

.iconCloseModal {
  @apply absolute top-3 right-3 cursor-pointer;
}

.modalContent {
  @apply flex flex-1 flex-col;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px] font-inter;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.body {
  @apply relative flex flex-col w-full flex-1 overflow-y-auto gap-[20px] pb-2 pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.avatarContent {
  @apply flex justify-center;
}

.bodyGroup {
  @apply grid grid-cols-2 gap-5;
}

.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}

.quickBooks {
  @apply absolute top-0 right-2 flex items-center justify-end;
}

.quickBooksLabel {
  @apply text-12-400-normal text-primary-zinc-500;
}

.quickBooksStatus {
  @apply w-4 h-4 rounded-full bg-gray-400 ml-2;
}

.quickBooksStatus.active {
  @apply bg-green-600;
}
