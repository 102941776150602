.container {
  @apply w-fit;
}

.default {
  @apply text-primary-zinc-500;
}

.active {
  @apply text-primary-emerald-900;
}

.inactive,
.pending,
.lead {
  @apply text-pizazz-500;
}

.invoiced {
  @apply text-primary-green-600/80;
}

.completed {
  @apply text-primary-green-600;
}

.rejected,
.overDue {
  @apply text-radical-red-500;
}

.paid {
  @apply text-primary-green-600;
}

.assigned {
  @apply text-primary-neutral-700;
}

.signed {
  @apply text-primary-neutral-700;
}

.onGoing {
  @apply text-blue-violet-600;
}

.canceled {
  @apply text-radical-red-500;
}

.inProgress {
  @apply text-primary-blue-500;
}

.onHold {
  @apply text-primary-orange-400;
}

.due {
  @apply text-primary-red-500;
}

.bidding,
.scheduled,
.outstanding {
  @apply text-common-007AFF;
}

.noBalanceDue,
.outForSignature {
  @apply text-primary-purple-500;
}

.approved {
  @apply text-primary-green-600;
}

.new {
  @apply text-common-007AFF;
}

.paused {
  @apply text-common-AF52DE;
}

.closedWithoutPerforming {
  @apply text-primary-zinc-800;
}

.default.tag,
.active.tag,
.inactive.tag,
.pending.tag,
.invoiced.tag,
.completed.tag,
.rejected.tag,
.paid.tag,
.assigned.tag,
.signed.tag,
.onGoing,
.inProgress.tag,
.onHold,
.due,
.noBalanceDue,
.approved,
.canceled.tag,
.new.tag,
.paused.tag,
.bidding.tag,
.lead.tag,
.outForSignature.tag,
.scheduled.tag,
.closedWithoutPerforming.tag,
.outstanding.tag,
.overDue.tag {
  @apply inline-flex text-12-400-18 py-[2px] px-[10px] rounded-full items-center justify-center;
}

.default.tag {
  @apply bg-primary-zinc-500/10;
}

.active.tag {
  @apply bg-common-DEF7EC;
}

.inactive.tag,
.pending.tag,
.lead.tag {
  @apply bg-pizazz-500/10;
}

.invoiced.tag {
  @apply bg-primary-green-600/10;
}

.completed.tag {
  @apply bg-primary-green-600/10;
}

.rejected.tag,
.overDue.tag {
  @apply bg-radical-red-500/10;
}

.paid.tag {
  @apply bg-primary-green-600/10;
}

.assigned.tag {
  @apply bg-primary-neutral-700/10;
}

.signed.tag {
  @apply bg-primary-neutral-700/10;
}

.onGoing.tag {
  @apply bg-blue-violet-600/10;
}

.canceled.tag {
  @apply bg-radical-red-500/10;
}

.inProgress.tag {
  @apply bg-primary-blue-500/10;
}

.onHold.tag {
  @apply bg-primary-orange-400/10;
}

.due.tag {
  @apply bg-primary-red-500/10;
}

.noBalanceDue.tag,
.outForSignature.tag {
  @apply bg-primary-purple-500/10;
}

.approved.tag {
  @apply bg-primary-green-600/10;
}

.new.tag {
  @apply bg-common-007AFF/10;
}

.paused.tag {
  @apply bg-common-AF52DE/10;
}

.bidding.tag,
.scheduled.tag,
.outstanding.tag {
  @apply bg-common-007AFF/10;
}

.closedWithoutPerforming.tag {
  @apply bg-primary-zinc-800/10;
}
