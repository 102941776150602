.container {
  @apply w-full;
}

.fileName {
  @apply flex flex-1 my-auto pl-2 text-12-400-normal text-primary-zinc-900 truncate gap-[6px];
}

.fileUpload {
  @apply gap-[6px] w-fit h-full flex items-center justify-center px-2 text-12-400-normal text-primary-cyan-600 cursor-pointer;
}

.fileInput {
  @apply hidden;
}

.boxInput {
  @apply min-h-[31px] h-[31px] border rounded-[6px] border-primary-cyan-600 border-dashed flex items-center justify-center bg-primary-cyan-600/5;
}

.label {
  @apply mb-2 text-12-400-normal text-primary-zinc-500;
}

.errorMessage {
  @apply min-h-4 text-12-400-15 text-primary-red-500 px-2 mt-1 line-clamp-2 break-words;
}

.fileName.placeholder {
  @apply text-12-400-normal text-primary-zinc-500;
}
