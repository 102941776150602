.container {
  @apply w-full flex flex-col;
}

.label {
  @apply inline-block max-w-full text-12-500-normal text-primary-zinc-500 text-wrap mb-2;
}

.viewStar {
  @apply text-primary-rose-500;
}

.datePickerGroup {
  @apply relative w-full h-[33px] p-[5px] rounded-[5px] border border-primary-gray-300 flex items-center justify-between gap-2;
}

.inputDatePicker {
  @apply flex-1 text-12-500-15 text-primary-zinc-900 outline-none;
}

.inputDatePicker::-webkit-calendar-picker-indicator {
  @apply w-[18px] h-[18px] mr-[2px] opacity-0 cursor-pointer;
}

.datePickerIcon {
  @apply absolute top-1/2 -translate-y-1/2 right-2 pointer-events-none;
}

.errorMessage {
  @apply min-h-4 px-2 text-primary-red-500 text-12-400-15 left-0 top-full mt-1;
}
