.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.detailMainContent {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.body {
  @apply w-full h-full overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.contentGroup {
  @apply w-full flex gap-6 mb-5;
  @apply last:mb-0;
}

.contentWrap {
  @apply basis-1/2 mb-6 gap-5;
}

.content {
  @apply flex flex-col gap-4;
}
