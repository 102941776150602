.textareaContainer {
  @apply relative w-full;
}

.textareaLabel {
  @apply flex text-12-400-normal text-primary-zinc-500 truncate capitalize mb-[10px];
}

.textareaWrap {
  @apply relative flex-1 flex flex-col bg-white px-2 py-3 border border-primary-gray-300 rounded-[5px] overflow-hidden;
}

.textarea {
  @apply flex-1 text-primary-zinc-800 text-12-400-15 outline-none resize-none;
  @apply disabled:bg-transparent;
  @apply placeholder:text-12-400-15 placeholder:text-primary-zinc-500;
}

// Error Message
.errorMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}

.required {
  @apply text-primary-rose-500;
}
