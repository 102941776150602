.btnGroup {
  @apply flex justify-center items-center p-2 gap-[7px] rounded-[5px] bg-white border border-gray-300 focus:outline-none text-14-400-normal font-inter text-primary-neutral-700 transition ease-in-out duration-300;

  &:hover {
    @apply bg-neutral-50;
  }
}

.btnImg {
  @apply h-[14px] w-[14px];
}

.popupFilterContainer {
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-white p-5 z-20 min-w-[260px] rounded-lg border shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  overflow: hidden !important;
}

.popTitle {
  @apply text-primary-neutral-700 font-inter text-16-600-normal;
}

.btnLabel {
  @apply text-12-500-normal text-primary-zinc-800;
}

// Button
.popBtnWrap {
  @apply flex justify-end;
}

.popBtnGroup {
  @apply flex items-center justify-between gap-[10px];
}

.popContent {
  @apply max-w-[700px] min-w-[236px] max-h-96 my-4 overflow-y-auto;
}

.emptyData {
  @apply w-full flex justify-center items-center;
}
