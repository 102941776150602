.container {
    @apply flex items-center -space-x-[6px];
}
  
.avatar {
    @apply w-[28px] h-[28px] rounded-full object-cover border border-primary-gray-300;
}

.avatarMore {
    @apply flex items-center justify-center w-[28px] h-[28px] rounded-full gap-[2px] bg-primary-neutral-200 relative;
}

.dot {
    @apply w-[3px] h-[3px] rounded-full bg-primary-zinc-500;
}