// Focus a row
.reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #1a73e8;
  box-shadow: 1px 2px 5px rgba(26, 115, 232, 0.3);
}

// Header Excel
.rg-cell.rg-header-cell.rg-cell-nonEditable {
  background: rgba(0, 0, 0, 0.04) !important;
}

// Select row
.reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
  border-color: #0e65eb;
  background-color: rgba(14, 101, 235, 0.1);
}

// Dropdown
.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-menu {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
  width: auto;
  min-width: 100%;
  max-width: 800px;
  padding-left: 2px;
  padding-right: 2px;
}

.rg-dropdown-option.selected {
  font-weight: 700;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.selected::before {
  content: '';
  padding: 0;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.focused {
  background-color: #f1f3f4;
}

.css-qgckm3-indicatorSeparator {
  background-color: transparent !important;
}
