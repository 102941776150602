.forgotPasswordContainer {
  @apply w-full h-full flex flex-col items-center justify-center;
}

.forgotPasswordTitle {
  @apply relative text-20-700-normal text-primary-zinc-800 mb-[30px] font-inter;
}

.forgotPasswordTitle::after {
  @apply content-[''] absolute top-full left-0 mt-[8px] w-[26px] h-1 bg-primary-cyan-600;
}

.formForgotPassword {
  @apply mb-[32px];
}

.forgotPasswordNote {
  @apply text-14-400-21 font-poppins text-black break-normal mb-[22px] pr-[50px];
}
