.statisticCard {
  @apply w-full h-[90px] py-[20px] px-[14px] border-[1px] border-primary-gray-300 rounded-lg;
}

.mainContent {
  @apply flex flex-row gap-[14px] items-center;
}

.labelGroup {
  @apply flex flex-col;
}

.iconCard {
  @apply bg-violet-200 w-[50px] h-[50px] rounded-lg flex justify-center items-center;
}

.iconCard.black {
  @apply bg-primary-zinc-800 w-[50px] h-[50px] rounded-lg flex justify-center items-center;
}

.iconCard.darkBlue {
  @apply bg-sky-950 w-[50px] h-[50px] rounded-lg flex justify-center items-center;
}

.statisticTitle {
  @apply text-17-600-20 text-primary-zinc-800 truncate h-[25px] flex flex-wrap;
}

.statisticValue {
  @apply text-14-400-normal text-primary-zinc-400 truncate h-[21px];
}
