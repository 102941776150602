.modalContainer {
  @apply fixed bg-black top-0 bottom-0 left-0 right-0 z-10 bg-opacity-25;
}

.modalBody {
  @apply w-[829px] pt-10 p-6 bg-white border rounded-[5px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-10 overflow-visible max-h-[80%] flex flex-col;
}

.modalHead {
  @apply mb-6;
}

.modalTitle {
  @apply text-17-600-20 text-primary-zinc-900 font-inter;
}

.modalContent {
  @apply flex flex-col overflow-y-scroll;
}

.assignedJob {
  @apply flex flex-col gap-[10px] mb-5;
}

.sectionTitle {
  @apply text-primary-zinc-500 text-12-400-15 font-inter;
}

.textContent {
  @apply text-12-400-15 text-sky-950 font-inter;
}

.noteContent {
  @apply flex flex-col gap-4;
}

.modalFooter {
  @apply w-full self-end mt-4;
}

.description {
  @apply text-12-400-15 font-inter;
}
