.container {
  @apply w-full h-full overflow-hidden;
}

.formContainer {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.formContent {
  @apply flex-1 grid grid-cols-1 gap-5 auto-rows-min overflow-y-auto pr-1 pb-1;
}

.formContent::-webkit-scrollbar {
  @apply w-1;
}

.formContent::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.formContent::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.twoCol {
  @apply grid grid-cols-2 gap-5 min-h-10;
}

.items {
  @apply grid grid-cols-1 gap-5;
}

.listItemGroup {
  @apply grid grid-cols-1 gap-5;
}

.itemsLabel {
  @apply text-12-400-normal text-primary-zinc-800;
}

.errorMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}

.itemsActions {
  @apply grid grid-cols-2 items-center justify-between gap-5;
}

.addItem {
  @apply w-fit flex items-center cursor-pointer;
}

.addItemIcon {
  @apply w-4 h-4 object-cover;
}

.addItemLabel {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.totalGroup {
  @apply w-fit ml-auto;
  @apply text-14-400-normal text-primary-zinc-500 truncate;
}

.formActions {
  @apply flex items-center justify-end pt-[30px] gap-3;
}

.line {
  @apply w-full bg-gray-300 h-[1px];
}

.poLabel {
  @apply text-14-500-normal text-primary-zinc-800;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.name {
  @apply truncate;
}
