.columnChartComponent {
    @apply border rounded-[4px] h-full w-full pt-5 px-5 flex flex-col;
}

.headerSection {
    @apply flex items-center justify-between mb-6 w-full;
}
  
.headerSectionText {
    @apply flex items-end justify-end;
}
  
.iconWrap {
    @apply flex w-6 h-6 rounded-[16px] items-center justify-center bg-primary-green-600;
}
  
.headTitle {
    @apply text-20-700-normal ms-2 text-primary-zinc-900;
}

.contentChart {
    @apply px-6;
}