.container {
  @apply w-full h-full;
}

.createScreen {
  @apply w-full h-full bg-primary-neutral-100/60 rounded-md;
  @apply flex justify-center items-center gap-x-6;
}

.btnLarge {
  @apply w-[220px] h-[186px] rounded-lg bg-white border border-primary-gray-300;
  @apply flex flex-col justify-center items-center gap-y-5;
  @apply hover:bg-gray-100;
}

.iconBtnLarge {
  @apply w-20 h-20;
}

.textBtnLarge {
  @apply text-16-500-24 text-primary-zinc-800;
}
