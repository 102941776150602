.modalContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px] font-inter;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-[20px] pb-2;
}

.inputContent {
  @apply gap-[20px] grid grid-cols-2;
}

.modalFooter {
  @apply flex gap-3 pt-[30px] justify-end;
}
