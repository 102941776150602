.container {
  @apply w-full text-left border-none outline-none;
}

.popupContainer {
  @apply flex flex-col z-10 w-[305px];
  @apply bg-white rounded-[6px] p-6;
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.body {
  @apply flex flex-col gap-2;
}

.content {
  @apply flex items-center w-full justify-between;
}

.title {
  @apply w-[40%] text-12-400-normal text-primary-neutral-400;
}

.value {
  @apply w-[60%] text-12-400-15 text-primary-zinc-800 truncate;
}

.buttonGroup {
  @apply flex justify-between gap-x-2 mt-6;
}

.btnUnschedule {
  @apply text-12-500-normal text-radical-red-500 bg-white border-[1px] border-radical-red-500/20 px-[26px] py-2 rounded-[5px] transition ease-in-out duration-300;

  &:hover {
    @apply bg-rose-50 border-rose-50;
  }
}

.btnDetails {
  @apply text-12-500-normal text-white bg-primary-cyan-600 border-primary-cyan-600 px-[26px] py-2 rounded-[5px] w-full transition ease-in-out duration-300;

  &:hover {
    @apply bg-opacity-80;
  }
}

.isExpanded {
  @apply w-full;
}

.tooltipWrap {
  @apply z-10 bg-black flex flex-1 flex-col opacity-100 rounded-[6px] right-7 !important;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-white;
}
