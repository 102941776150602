.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px];
}

.body {
  @apply border h-full w-full flex flex-col overflow-hidden rounded-lg mr-[14px] p-6;
}

.clientGroup {
  @apply flex items-center justify-end gap-3;
}

.clientTitle {
  @apply text-14-400-normal text-primary-neutral-400;
}

.clientName {
  @apply text-14-500-normal text-primary-cyan-600 truncate cursor-pointer transition ease-in-out duration-300;
  @apply hover:text-opacity-80;
}

.taskDetailContainer {
  @apply w-full flex gap-6 pr-2 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.taskDetail,
.taskMap {
  @apply basis-1/2 flex flex-col gap-4;
}

.taskMap {
  @apply gap-5;
}

.map {
  @apply h-[522px] rounded-xl overflow-auto border border-primary-gray-300 p-[10px];
}

.locationGroup {
  @apply flex items-center gap-2;
}

.locationKey {
  @apply text-14-400-normal text-primary-neutral-400;
}

.locationValue {
  @apply text-14-400-normal text-primary-zinc-800;
}

.quickBookWrap {
  @apply flex items-center justify-center;
}

.greenCircle {
  @apply w-4 h-4 shrink-0 bg-green-600 rounded-full;
}

.grayCircle {
  @apply w-4 h-4 shrink-0 bg-gray-400 rounded-full;
}

.valueGroup {
  @apply flex items-center gap-2;
}
