.customToolbarContainer {
  @apply flex flex-col;
}

.limitText {
  @apply flex items-center flex-1 justify-end text-12-500-normal text-primary-gray-400;
}

.headerName {
  @apply text-14-500-17 text-primary-zinc-800 truncate;
}
