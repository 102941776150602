.container {
  @apply w-full h-full overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.jobDetailsItem {
  @apply w-full flex items-center gap-2 mb-5;
  @apply last:mb-0;
}

.key {
  @apply text-14-400-normal text-primary-neutral-400 last:mb-0 text-wrap;
}

.value {
  @apply text-14-400-normal text-primary-zinc-800 last:mb-0 text-wrap;
}

.jobDetailsSection {
  @apply w-full;
}

.jobDetailsTitle {
  @apply text-14-500-normal text-primary-zinc-800 mb-2;
}

.jobDetailsWrap {
  @apply basis-1/2 mb-6 gap-5;
}

.jobDetailsContainer {
  @apply w-full flex gap-6 mb-5;
  @apply last:mb-0;
}

.mapSection {
  @apply basis-1/2;
}

.map {
  @apply h-[522px] rounded-xl overflow-auto border border-primary-gray-300 p-[10px];
}

.jobDetails {
  @apply flex flex-col gap-4;
}
