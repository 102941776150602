.container {
  @apply flex flex-col overflow-hidden;
}

.header {
  @apply text-20-700-normal text-primary-zinc-800 mb-5;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.lineBot {
  @apply w-full bg-gray-300 h-[1px] mb-[30px] mt-6;
}

.lineCenter {
  @apply w-full bg-gray-300 h-[1px];
}

.titleContent {
  @apply text-16-400-24-2 text-primary-zinc-800;
}

.billTitle,
.sentTitle {
  @apply mb-2;
}

.billContent,
.sentContent {
  @apply mb-2;
}

.titleSpace,
.contentSpace {
  @apply mb-3;
  @apply last:mb-0;
}

.content {
  @apply text-14-400-21-2 text-primary-zinc-500;
}

.body {
  @apply flex-1 overflow-y-auto grid gap-6 pr-2;
}

.twoCol {
  @apply grid grid-cols-2 gap-[30px];
}

.alignLeft,
.alignRight {
  @apply w-full overflow-hidden;
}

.alignLeft {
  @apply flex flex-col items-start;
}

.alignRight {
  @apply flex flex-col items-end;
}

.id {
  @apply px-4 py-[10px] rounded-xl bg-primary-neutral-100;
  @apply text-14-500-21-2 text-primary-zinc-800;
}

.invoiceDetailItem {
  @apply grid grid-cols-[150px_1fr] gap-5;
}

.key {
  @apply text-14-400-21-2 text-primary-gray-400;
}

.value {
  @apply text-14-400-21-2 text-primary-zinc-500;
}

.totalItem {
  @apply w-full grid grid-cols-[216px_1fr] mt-3 mb-3;
  @apply first:mt-0;
  @apply last:mb-0;
}

.totalKey,
.totalValue {
  @apply text-12-400-normal text-primary-zinc-900;
}

.totalBold {
  @apply text-12-500-normal text-primary-zinc-800;
}

.contactWrap {
  @apply p-5 rounded-xl bg-primary-stone-50 grid grid-cols-1 gap-[10px];
}

.contactContent {
  @apply text-14-400-21-2 text-primary-zinc-500;
}

.contactContentHightLight {
  @apply text-primary-zinc-800;
}

.tableWrap {
  min-height: 150px;
}

// Scroll bar
.body::-webkit-scrollbar {
  @apply w-1;
}

.body::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.body::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

// Actions
.actions {
  @apply flex gap-3 justify-end;
}
