.container {
  @apply flex flex-col h-full overflow-hidden;
}

.header {
  @apply text-20-700-normal text-primary-zinc-800 mb-5;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.mainContentWrap {
  @apply flex-1 flex flex-col overflow-hidden;
}

.body {
  @apply flex-1 overflow-y-auto pr-2;
}

.generalInfo {
  @apply flex flex-col gap-y-5;
}

.rowInfo {
  @apply w-full flex items-center gap-2;
}

.key {
  @apply text-14-400-normal text-primary-neutral-400 last:mb-0 text-wrap;
}

.value {
  @apply text-14-400-normal text-primary-zinc-800 last:mb-0 text-wrap;
}

.table {
  @apply mt-10;
}

.actions {
  @apply flex items-center justify-end gap-3 mt-[30px];
}

// Scroll bar
.body::-webkit-scrollbar {
  @apply w-1;
}

.body::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.body::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

// Table
.avatarFirstLastName {
  @apply flex items-center gap-2;
}

.documentLink {
  @apply text-12-400-normal text-primary-cyan-600 underline;
}
