.baseTimePickerContainer {
  @apply w-full;
}

#baseTimePickerComponent .labelField {
  @apply block mb-[10px] text-12-400-normal text-primary-zinc-500 truncate;
}

.baseTimePickerContent {
  @apply w-full;
}

.viewStar {
  @apply text-primary-rose-500;
}

// Custom lib
#baseTimePickerComponent {
  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker {
    @apply w-full m-0 font-inter text-14-400-normal text-primary-zinc-900 bg-white rounded-lg py-4 pl-1 pr-1;
    @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  }

  .react-datepicker__header {
    @apply hidden;
  }

  .react-datepicker__time-container {
    @apply w-auto;
  }

  .react-datepicker__time-box {
    @apply w-auto;
  }

  .react-datepicker__time-list {
    box-sizing: border-box !important;
    @apply flex flex-col justify-around gap-[5px] pr-2;
  }

  .react-datepicker__time-list-item {
    @apply h-auto inline-block text-center text-12-400-normal text-primary-zinc-900 rounded;
  }

  .react-datepicker__time-list-item--selected {
    @apply bg-primary-cyan-600 text-white;
  }
}

// Scroll bar
#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar {
  @apply w-1;
}

#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

#baseTimePickerComponent .react-datepicker__time-list::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}
