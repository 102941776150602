.donutChartComponent {
  @apply w-full h-full py-7 px-[33px] border rounded-[8px] bg-white overflow-hidden;
}

.headerChart {
  @apply flex flex-col justify-between gap-1 mb-6;
}

.headTitle {
  @apply text-20-700-normal text-primary-zinc-800 truncate;
}

.bodyChartContainer {
  @apply flex flex-col gap-[50px] mt-[30px] justify-center items-center;
}

.footerChart {
  @apply flex flex-col items-center gap-4;
}

.legendItem {
  @apply w-[314px] flex items-center justify-between gap-2;
}

.itemWrap {
  @apply flex items-center gap-3;
}

.label {
  @apply text-16-600-normal text-primary-neutral-400 truncate;
}

.percentLabel {
  @apply text-16-600-normal text-primary-zinc-800 truncate;
}

.footerChart {
  @apply flex flex-col items-center gap-4 mt-2;
}

.legendItem {
  @apply w-[314px] flex items-center justify-between gap-2;
}

.itemWrap {
  @apply flex items-center gap-3;
}

.dotWrap {
  @apply relative w-3 h-3 rounded-full flex items-center justify-center;
}

.dotWrap.blue {
  @apply bg-blue-600 bg-opacity-[32%];
}

.dotWrap.green {
  @apply bg-green-800 bg-opacity-[32%];
}

.dotWrap.violet {
  @apply bg-violet-200 bg-opacity-[32%];
}

.dotWrap.sky {
  @apply bg-sky-950 bg-opacity-[32%];
}

.dotWrap.orange {
  @apply bg-primary-orange-200 bg-opacity-[32%];
}

.dotWrap.green {
  @apply bg-primary-green-600 bg-opacity-[32%];
}

.dotWrap.blue {
  @apply bg-sky-500 bg-opacity-[32%];
}

.dotWrap.amber {
  @apply bg-primary-amber-300 bg-opacity-[32%];
}

.dotWrap.purple {
  @apply bg-primary-purple-500 bg-opacity-[32%];
}

.dot {
  @apply w-[6px] h-[6px] rounded-full;
}

.blue .dot {
  @apply bg-sky-500;
}

.green .dot {
  @apply bg-primary-green-600;
}

.orange .dot {
  @apply bg-primary-orange-200;
}

.purple .dot {
  @apply bg-primary-purple-500;
}

.amber .dot {
  @apply bg-primary-amber-300;
}

.violet .dot {
  @apply bg-violet-200;
}

.sky .dot {
  @apply bg-violet-950;
}

.label {
  @apply text-14-400-24 text-primary-zinc-800 truncate;
}

.percentLabel {
  @apply text-16-600-normal text-primary-zinc-800 truncate;
}
