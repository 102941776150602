.sidebarContainer {
  @apply w-full h-full bg-sky-950 border border-primary-gray-300 rounded-3xl py-[30px] flex flex-col;
}

.head {
  @apply flex items-center justify-center cursor-pointer pb-6;
}

.images {
  @apply w-[224px] h-[40px];
}

.body {
  @apply flex-1 overflow-y-auto;
}

.body::-webkit-scrollbar {
  @apply hidden;
}

.menuList {
  @apply flex flex-col gap-2;
}

.menuGroup {
  @apply pt-4;
}

.logout {
  @apply px-8 flex gap-4 opacity-[50%] cursor-pointer py-3;
}

.btnLogout {
  @apply text-white text-14-500-17 font-inter truncate;
}
