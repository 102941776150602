.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
  @apply relative;
}

.layout {
  @apply size-full flex justify-between gap-x-8 overflow-y-auto pr-1;
}

.layout::-webkit-scrollbar {
  @apply w-1;
}

.layout::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.layout::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.verticalLine {
  @apply w-[1px] h-full bg-porcelain-100;
  @apply absolute top-0 bottom-0 right-[404px];
}

.horizontalLine {
  @apply w-full h-[1px] bg-porcelain-100;
}

.title {
  @apply text-14-500-normal text-primary-zinc-800 mb-5;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}

// Value Item
.jobDetailsItem {
  @apply w-full flex items-center gap-2;
}

.key {
  @apply text-14-400-normal text-primary-neutral-400;
}

.value {
  @apply text-14-400-normal text-primary-zinc-800;
}

.italicStyle {
  @apply italic;
}

.boldStyle {
  @apply font-semibold;
}

// Detail info
.detailContent {
  @apply flex-1;
}

.mainContent {
  @apply grid grid-cols-1 gap-5 auto-rows-min mt-5;
}

.items {
  @apply grid grid-cols-1 gap-5;
}

.listItemGroup {
  @apply grid grid-cols-1 gap-4;
}

.itemsLabel {
  @apply text-12-400-normal text-primary-zinc-800;
}

.totalField {
  @apply justify-self-end;
}

// Additional Info
.additionalInfoContent {
  @apply w-[360px];
}

.documentContainer {
  @apply w-full grid grid-cols-3 gap-3 mt-2;
}

.fileDocument {
  @apply h-[112px] p-2 overflow-hidden bg-primary-neutral-200/40 rounded-md;
}

.fileImg {
  @apply size-full rounded-md object-cover;
}

.fileIcon {
  @apply size-full p-2 bg-primary-neutral-200 rounded-md object-cover;
}

.noteContent {
  @apply w-full mt-2;
}
