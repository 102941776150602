.groupContainer {
  @apply w-full h-full flex items-center gap-[6px] pl-3;
}

.avatar {
  @apply w-8 h-8 object-cover rounded-full;
}

.groupName {
  @apply text-14-400-normal text-primary-zinc-900 truncate;
}
