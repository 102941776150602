.modalContainer {
  @apply fixed bg-black top-0 bottom-0 left-0 right-0 z-10 bg-opacity-25 font-inter;
}

.modalContent {
  @apply w-[780px] p-8 bg-white border rounded-[5px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-visible max-h-[80%] flex flex-col;
}

.modalHead {
  @apply mb-8;
}

.closeIcon {
  @apply absolute top-[10px] right-[10px] cursor-pointer;
}

.modalTitle {
  @apply text-20-700-normal border-b pb-5 border-gray-300;
}

.modalBody {
  @apply flex flex-1 flex-col overflow-y-auto gap-5 pb-2 pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.jobDetailSection {
  @apply grid grid-cols-2 col-span-1 gap-y-5 gap-x-4;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.modalFooter {
  @apply mt-[30px] flex flex-row justify-end gap-4;
}

// .timePicker {
//   @apply mt-3;
// }

// .tagNote {
//   @apply mt-[4px];
// }
