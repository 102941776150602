.container {
  @apply w-full h-full flex flex-col items-center justify-center;
}

.content {
  @apply w-full;
}

.content {
  @apply flex flex-col items-center;
}

.title {
  @apply relative text-20-600-30 text-primary-zinc-800 mb-5 font-inter text-center;
}

.gifSuccess {
  @apply w-[193px] h-[193px] mb-10 mt-5;
}
