.form {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.body {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-5 pr-2 relative;
}

.body::-webkit-scrollbar {
  @apply w-1;
}

.body::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.body::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.actions {
  @apply flex gap-3 mt-[30px] justify-end;
}

.twoColumns {
  @apply grid grid-cols-2 gap-5;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.templateName {
  @apply truncate;
}
