// Button
.btnWrap {
  @apply flex justify-between items-center rounded-[5px] focus:outline-none bg-primary-cyan-600 text-12-500-15 font-inter;
}

.btnLabel {
  @apply text-white w-full h-full rounded-l-[5px] z-10;
}

// Icon
.iconContainer {
  @apply cursor-pointer p-2 border-l border-white  outline-none;
}

.iconActive {
  @apply rotate-180;
}

// Popup
.popupContainer {
  @apply z-50 rounded-[8px] border min-w-[180px] border-primary-seashell-100 bg-white m-1 p-2;
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

// Content
.popContent {
  @apply flex flex-col gap-2;
}

.disable {
  @apply text-14-500-17 bg-slate-300 text-white truncate font-inter;
}

.bgrOpacityDisable {
  @apply bg-neutral-100 border-primary-gray-300 border-[1px] text-12-500-normal text-neutral-400;
}
