.container {
  @apply w-full h-full flex flex-col overflow-y-auto;
}

.viewListItem {
  @apply flex flex-col gap-[20px];
}

.titleContent {
  @apply text-16-600-normal text-primary-zinc-900 font-inter py-3 border-y border-primary-gray-300 text-center bg-gray-50;
}

.buttonAction {
  @apply px-2 text-12-400-normal text-primary-cyan-600;
}

.viewCheckBoxBreakdown {
  @apply flex flex-1 ml-[2px] mt-2;
}

.itemAddLineItem {
  @apply flex items-start justify-between;
}

.inputDescription {
  @apply flex w-[60%] gap-2 items-center;
}

.inputValue {
  @apply w-[30%];
}

.numerical {
  @apply text-12-400-normal text-primary-neutral-400;
}

.checkBox {
  @apply float-end mt-3;
}

.buttonRemoveLineItem {
  @apply h-full mt-[10px];
}

.viewCheckboxComment {
  @apply flex flex-1;
}

.viewButton {
  @apply w-full flex flex-col gap-3 items-start;
}

.fieldContainer {
  @apply w-full px-8 pt-4;
}

.btnAddWrap {
  @apply px-6 pt-2;
}

.contentBreakdownOrBill {
  @apply border-[1px] h-[100px];
}

.viewItem {
  @apply mt-2 pl-6;
}

.viewComment {
  @apply w-full ml-1 border border-primary-gray-300 pb-2 px-4;
}

.iconEditContact {
  @apply h-5;
}

.viewDetailComment {
  @apply w-full flex flex-col gap-3 items-start pt-1;
}

.buttonComment {
  @apply px-1;
}
