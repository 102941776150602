.eventContainer {
  @apply overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventGroup {
  @apply rounded relative border border-solid z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  @apply before:rounded before:border before:border-solid before:border-black;
}

.collapseEventContainer {
  @apply px-[10px];
}

.expandEventContainer {
  @apply p-[6px] flex flex-col gap-y-[6px] max-h-[95px];
}

// Scroll Style
.eventContainer.rct-item::-webkit-scrollbar {
  @apply w-1;
}

.eventContainer.rct-item::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.eventContainer.rct-item::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.eventName {
  @apply truncate text-12-400-normal text-primary-zinc-800;
}

.eventTime {
  @apply truncate mt-[2px] text-10-400-normal text-primary-zinc-500;
}

.assigneeContainer {
  @apply mt-auto flex gap-x-1;
}

.avatar {
  width: 26px;
  height: 26px;
  background-color: #a38383;
  border-radius: 100%;
}

// Overwrite css of lib
.eventContainer.rct-item {
  line-height: normal !important;
  background: unset !important;
  border: unset !important;
  color: unset !important;
  font-size: unset !important;
  padding: 6px;
}

.expandEventDay {
  @apply flex flex-row items-center h-[46px] gap-x-[14px] mt-4 overflow-hidden text-ellipsis flex-nowrap text-nowrap;
}
