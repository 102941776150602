.iconCloseModal {
  @apply absolute top-[10px] right-[10px] cursor-pointer;
}

// Main icon
.mainIconGroup {
  @apply w-full flex justify-center items-center mt-2;
}

.bgMainIcon {
  @apply w-20 h-20 flex justify-center items-center rounded-full;
}

.bgMainIcon.deactivateBg {
  @apply bg-sunset-orange-500/10;
}

.bgMainIcon.activateBg {
  @apply bg-common-DEF7EC;
}

.mainIcon {
  @apply w-10 h-10 object-cover;
}

// Title
.title {
  @apply mt-[30px] text-common-031B13 text-24-400-normal text-center;
}

.description {
  @apply mt-3 px-[10px] text-common-858C8A text-16-400-normal text-center;
}

.modalFooter {
  @apply mt-[30px] w-full flex justify-between items-center gap-[10px];
}
