.statisticTopItemComponent {
  @apply border-[1px] h-fit border-primary-gray-300 rounded-[4px] p-5;
}

.statisticTopItem {
  @apply flex flex-col gap-6;
}

.group {
  @apply flex gap-4 items-center;
}

.images {
  @apply rounded-full object-cover w-[44px] h-[44px];
}

.labelGroup {
  @apply flex flex-col gap-1;
}

.name {
  @apply text-17-600-20 text-primary-zinc-800 font-inter truncate;
}

.description {
  @apply text-12-400-15 text-primary-neutral-400 truncate font-inter;
}

.rate {
  @apply text-14-500-17 text-primary-zinc-800 truncate font-inter;
}
