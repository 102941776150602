.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.form {
  @apply w-full h-full flex flex-col gap-[30px] overflow-hidden;
}

.formBody {
  @apply flex-1 pr-2 grid grid-cols-1 auto-rows-max gap-5 overflow-y-auto;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.formFooter {
  @apply flex items-center justify-end gap-3;
}

.twoCol {
  @apply grid grid-cols-2 auto-rows-max gap-5;
}

.threeCol {
  @apply grid grid-cols-3 auto-cols-max gap-5;
}

.group {
  @apply grid grid-cols-1 gap-5;
}

.groupTitle {
  @apply text-14-500-normal text-primary-zinc-800 border-b border-gray-300 pb-5;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}
