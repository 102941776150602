.originStyle {
  @apply w-full h-full flex justify-center items-center py-2 px-3 rounded-[5px] gap-2 bg-primary-blue-600 text-white text-12-500-15 font-inter;
}

.primary {
  @apply rounded-[8px] text-14-500-normal;
}

.originStyle.border {
  @apply border-primary-gray-300 border-[1px];
}

.originStyle.cancel {
  @apply bg-white border-primary-gray-300 border-[1px] text-12-500-normal text-neutral-400 transition ease-in-out duration-300;

  &:hover {
    @apply opacity-50;
  }
}

.iconButton {
  @apply w-5 h-5;
}

// Custom BaseButton solidPrimary
.originStyle.solidPrimary {
  @apply bg-primary-cyan-600 text-white transition ease-in-out duration-300;

  &:hover {
    @apply bg-opacity-80;
  }
}

// Custom BaseButton OnlyText
.originStyle.onlyText {
  @apply bg-transparent text-primary-cyan-600 underline;
}

// Custom BaseButton solidSecondary
.originStyle.solidSecondary {
  @apply text-white bg-sky-950;
}

// Custom BaseButton auth
.originStyle.auth {
  @apply text-14-500-17 bg-primary-cyan-600 text-white truncate font-inter;
}

// Custom BaseButton red color
.originStyle.red {
  @apply bg-primary-red-600 text-white;
}

// Custom BaseButton gray color
.originStyle.gray {
  @apply bg-gray-500 text-white;
}

// Custom BaseButton disable
.originStyle.disable {
  @apply text-14-500-17 bg-slate-300 text-white truncate font-inter;
}

.originStyle.bgrOpacityDisable {
  @apply bg-neutral-100 border-primary-gray-300 border-[1px] text-12-500-normal text-neutral-400;
}

.originStyle.small {
  @apply bg-white border-primary-gray-300 border-[1px] text-gray-600;
}

// Custom BaseButton deactivate
.originStyle.deactivate {
  @apply text-red-600 border-[1px] border-red-600 bg-white;
}

// Custom BaseButton solidLine
.originStyle.solidLine {
  @apply bg-white text-primary-cyan-600 border-primary-cyan-600 border-[1px];
}

// Custom BaseButton redSystem
.originStyle.redSystem {
  @apply text-radical-red-500 bg-white border-[1px] border-radical-red-500/20 transition ease-in-out duration-300;

  &:hover {
    @apply bg-rose-50 border-rose-50;
  }
}

.loadingContainer {
  @apply flex justify-center items-center;
}

.loadingContent {
  @apply w-5 h-5 inline-block box-border animate-spin rounded-full border-[2px] border-sky-950 border-b-transparent border-solid;
}
