.baseDatePickerContainer {
  @apply w-full;
}

#baseDatePickerComponent .labelField {
  @apply block pb-[10px] text-12-400-normal text-primary-zinc-500 truncate;
}

.baseDatePickerContent {
  @apply w-full;
}

.viewStar {
  @apply text-primary-rose-500;
}

// Custom lib
#baseDatePickerComponent {
  .react-datepicker-wrapper {
    @apply w-full;
  }

  .react-datepicker {
    @apply w-[350px] m-0 font-inter text-14-400-normal text-primary-zinc-900 bg-white rounded-lg p-5;
    @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
  }

  // Navigation
  .react-datepicker__navigation {
    @apply top-[22px];
  }

  .react-datepicker__navigation--previous {
    @apply left-5;
  }

  .react-datepicker__navigation--next {
    @apply right-5;
  }

  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon::before {
    @apply border-t-[1.5px] border-r-[1.5px] border-primary-gray-400;
  }

  // Current month
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    @apply text-14-500-normal text-primary-zinc-900 h-8 flex items-center justify-center;
  }

  // Header
  .react-datepicker__header {
    @apply border-none p-0 text-primary-zinc-900 bg-white;
  }

  // Day of week
  .react-datepicker__day-names {
    @apply inline-flex mt-3 w-full justify-between;
  }

  .react-datepicker__day-name {
    @apply text-primary-zinc-900 text-12-400-normal m-0;
    @apply flex justify-center items-center w-8 h-8;
  }

  // Day of month
  .react-datepicker__month {
    @apply m-0;
  }

  .react-datepicker__week {
    @apply inline-flex mt-1 w-full justify-between;
  }

  .react-datepicker__day {
    @apply text-primary-zinc-900 text-12-400-normal rounded-full m-0;
    @apply flex justify-center items-center  w-8 h-8;
  }

  // Offside day
  .react-datepicker__day--outside-month {
    @apply text-primary-gray-400;
  }

  .react-datepicker__day:hover:not(.react-datepicker__day--selected) {
    @apply text-primary-cyan-600 rounded-full bg-primary-cyan-600/10;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    @apply text-white;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    @apply relative z-[1] bg-primary-cyan-600;
  }
}

.tooltipWrap {
  @apply z-10 bg-black flex flex-1 flex-col opacity-100 rounded-[6px] !important;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-white;
}

.labelFieldContainer {
  @apply flex flex-row;
}

.valueLabel {
  @apply text-12-400-normal text-neutral-500 truncate;
}

.iconQuestion {
  @apply ml-1 align-bottom;
  @apply hover:opacity-70;
}
