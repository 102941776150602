.dropdownProfileComponent {
  @apply relative;
}

.headerAvatarWrap {
  @apply flex items-center cursor-pointer focus:outline-none w-[232px] justify-between gap-2;
  @apply px-3 py-1;

  &:hover {
    @apply transition ease-in-out duration-200 hover:bg-gray-100 rounded-lg;
  }
}

.imageAvatar {
  @apply w-10;
}

.headerAvatarImg {
  @apply flex gap-3 w-[85%];
}

.headerAvatar {
  @apply max-w-10 w-10 h-10 rounded-full object-cover;
}

.headerNameWrap {
  @apply flex flex-col gap-1 flex-1 w-[calc(100%-52px)];
}

.headerName {
  @apply text-14-500-17 text-primary-zinc-800 text-left;
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.headerRole {
  @apply text-12-400-normal text-primary-gray-400 text-left;
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.headerAvatarOption {
  @apply z-[10] w-[232px] bg-white rounded-[8px] shadow-[0_20px_40px_0px_rgba(0,15,18,0.1)] border-primary-neutral-100 border flex flex-col gap-3 p-[20px];
  @apply data-[closed]:scale-95 data-[closed]:opacity-0;
  @apply transition duration-200 ease-in-out;
  overflow: visible !important;
}

.arrow {
  @apply absolute h-4 bg-[white] rotate-45 z-[-1] left-[50%] -translate-x-1/2 -top-2 w-4 rounded-[34x] border-t border-l border-primary-neutral-100;
}

.headerOptionGroup {
  @apply flex flex-col gap-1;
}

.headerOptionAvatar {
  @apply flex flex-row gap-3 justify-start;
}

.headerOptionWrap {
  @apply w-full p-2;
  @apply hover:bg-gray-100 hover:rounded-md;
}

.headerOptionWrapLogout {
  @apply hover:bg-primary-rose-500/10 hover:rounded-md;
}

.horizontalLine {
  @apply h-[1px] w-full bg-primary-gray-300;
}

.headerOptionLogout {
  @apply flex w-full text-12-400-normal text-primary-red-600 text-left justify-start items-center gap-[6px];
}

.headerOptionText {
  @apply flex w-full text-12-400-normal text-primary-zinc-800 text-left justify-start items-center gap-[6px];
}

.headerDropdown {
  @apply w-5 h-5 object-cover block;
}
