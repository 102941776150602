.loadingContainer.overlay {
  @apply fixed z-10 bg-black bg-opacity-25 flex justify-center items-center top-0 left-0 right-0 bottom-0 w-full h-full;
}

.loadingContainer.noOverlay {
  @apply absolute z-10 flex justify-center items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.loadingContent {
  @apply w-10 h-10 inline-block box-border animate-spin rounded-full border-[5px] border-sky-950 border-b-transparent border-solid;
}
