// Form
.formContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px];
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.body {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-[20px] pb-2 pr-2;
}

// Scroll bar
.modalBody::-webkit-scrollbar {
  @apply w-1;
}

.modalBody::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.modalBody::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.inputContent {
  @apply gap-[20px] grid grid-cols-2;
}

.modalFooter {
  @apply flex gap-3 pt-[30px] justify-end;
}

.titleLine {
  @apply flex flex-col gap-5 mb-5;
}

.lineHr {
  @apply w-full bg-gray-300 h-[1px];
}

.title {
  @apply text-14-500-normal text-primary-zinc-800;
}

.formListGroup {
  @apply flex flex-col gap-y-5;
}

.iconAddField {
  @apply object-cover;
}

.btnRemoveField,
.iconAddFile,
.iconAddField {
  @apply w-4 h-4;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.buttonClose {
  @apply mt-6;
}
