.toastContainer {
  @apply relative w-full;
}

.toast {
  @apply absolute w-full z-[999] top-[30px];
}

.left {
  @apply left-[16px];
}

.center {
  @apply left-1/2 transform -translate-x-1/2;
}

.right {
  @apply right-[16px];
}

.toastItem {
  @apply absolute w-[343px] flex items-center justify-between text-white rounded-lg p-[14px] pr-[8px] bg-slate-700 border-b-[5px];
}

.toastItemContent {
  @apply flex items-center;
}

.toastItemLogo {
  @apply bg-white w-[34px] h-[34px] rounded-3xl mr-[8px];
}

.toastItemTitle {
  @apply text-14-500-normal w-[250px] truncate my-[4px];
}

.toastItemDescription {
  @apply text-12-400-normal w-[250px] truncate;
}

.toastItemTime {
  @apply text-12-500-normal;
}

.toastItemClose {
  @apply cursor-pointer;
}

.success {
  @apply border-green-500;
}

.warning {
  @apply border-yellow-300;
}

.error {
  @apply border-primary-red-500;
}
