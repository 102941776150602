.container {
  @apply w-full flex flex-col overflow-hidden pr-[14px] overflow-y-scroll;
}

.contentCustomerInfo {
  @apply flex flex-row gap-x-8;
}

.contentChildren {
  @apply h-[212px] w-[238px] border-[1px] border-gray-300 px-4 pt-4 pb-[10px];
}

.contentChildren.contentPropertyPhoto {
  @apply mt-8 h-[212px];
}

.row {
  @apply flex flex-row mt-4 gap-2 text-14-400-normal text-zinc-800;
}

.propertyPhoto {
  @apply w-[206px] h-[138px];
}

.title {
  @apply text-14-500-normal mb-[24px];
}

.nameCustomer {
  @apply text-14-500-normal;
}

.listCustomerTag {
  @apply flex flex-row gap-x-1 items-center;
}

.itemCustomerTag {
  @apply bg-violet-200 text-10-400-normal py-[3px] px-[9px] rounded-sm;
}
