.twoColumns {
  @apply grid grid-cols-2 gap-5;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.jobName {
  @apply truncate;
}
