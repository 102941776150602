.columnChartComponent {
  @apply w-full h-full py-7 px-[33px] border rounded-[8px] bg-white overflow-hidden;
}

.headerContainer {
  @apply flex flex-col justify-between gap-1 mb-6;
}

.headTitle {
  @apply text-20-700-normal  text-primary-zinc-900;
}

.headDescription {
  @apply text-16-400-normal text-primary-neutral-400 truncate;
}

.headDescriptionData {
  @apply text-24-400-normal text-primary-neutral-400 truncate mr-2;
}

.contentChart {
  @apply px-6;
}
