.container {
  @apply relative;
}

.optionsWrap {
  @apply absolute right-0 top-full mt-2 max-w-[300px] bg-white rounded-[5px] pt-[14px] pr-[10px] pb-[10px] pl-[17px] shadow-[0_0_4px_2px_rgba(0,0,0,0.12)] z-10;
}

.options {
  @apply flex flex-col gap-[18px] mb-[30px];
}

.option {
  @apply flex items-center justify-between gap-2;
}

.label {
  @apply text-14-400-normal text-primary-zinc-500 truncate;
}

.actions {
  @apply flex items-center justify-end gap-2;
}

.selectWrap {
  @apply bg-primary-gray-300 rounded-[5px];
}
