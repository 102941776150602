.adminDetailVendorModal {
  @apply fixed top-0 right-0 bottom-0 left-0 bg-black/25 z-10 flex justify-center items-center;
}

.modalDetails {
  @apply flex flex-1 flex-col overflow-hidden;
}

.contentVendorDetails {
  @apply flex flex-col w-full flex-1 overflow-y-auto pr-2 mt-4;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.modalTitleVendorDetails {
  @apply text-primary-zinc-800 text-20-700-normal border-b-[1px] pb-4;
  border-color: rgba(9, 9, 11, 0.1);
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.detailVendor {
  @apply flex flex-col gap-5 my-4 w-full;
}

.rowDetailVendor {
  @apply flex w-full items-center;
}

.labelVendor {
  @apply text-14-400-17 text-primary-neutral-400 w-[150px];
}

.additionalContactDetailsModal {
  @apply pt-[30px] pb-[30px] flex flex-col gap-5;
}

.content {
  @apply flex flex-col gap-4;
}

.contactHeader {
  @apply grid grid-cols-10 gap-5 border-b pb-3;
  border-color: rgba(9, 9, 11, 0.05);
}

.titleContactHeader {
  @apply col-span-3 text-14-400-normal text-primary-neutral-400;
}

.vendorDetailsModal {
  @apply flex flex-1 flex-col overflow-y-scroll;
}

.modalTitleContacts {
  @apply text-14-500-17 text-primary-zinc-800 border-b pb-4;
  border-color: rgba(9, 9, 11, 0.1);

  &.noBorder {
    @apply border-b-0 pb-0;
  }
}

.additionalContactDetailItem {
  @apply grid gap-5 grid-cols-10 border-b pb-4;
  border-color: rgba(9, 9, 11, 0.05);

  &.noBorder {
    @apply border-b-0 pb-0;
  }
}

.additionalContactDetailItem:last-child {
  @apply border-b-0 pb-0;
}

.titleContact {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-3;
}

.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}

.buttonAction {
  @apply w-[40px] h-[40px];
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.textDetailVendor {
  @apply text-14-400-normal text-primary-zinc-800;
}

.formAddContacts {
  @apply flex justify-between gap-5;
}

.formAddContactsContainer {
  @apply flex-1 grid grid-cols-3 gap-5 overflow-hidden;
}

.formAddContactsActions {
  @apply flex gap-3 mt-[30px];
}

.addContactsAction {
  @apply w-5 h-5 rounded-md outline-none overflow-hidden;
}

.formActions {
  @apply flex items-center gap-3;
}
