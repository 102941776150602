.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[750px] max-h-[80%] bg-white p-[30px] rounded-lg flex flex-col overflow-hidden;
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.title {
  @apply text-20-700-normal text-black truncate pb-5 border-b border-primary-gray-300 mb-[30px];
}

.form {
  @apply flex-1 flex flex-col overflow-hidden;
}

.body {
  @apply flex-1 grid grid-cols-1 gap-5 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.actions {
  @apply flex items-center justify-end gap-3 mt-10;
}

.twoColumns {
  @apply grid grid-cols-2 gap-5;
}

.threeColumns {
  @apply grid grid-cols-3 gap-5;
}

.groupTitle {
  @apply text-14-500-normal text-primary-zinc-800;
}

.garageHand {
  @apply flex items-center gap-5;
}

.asyncWithQuickBooks {
  @apply mt-[25px];
}
