.container {
  @apply flex flex-col gap-[20px];
}

// Common using
.twoColumn {
  @apply gap-[20px] grid grid-cols-2;
}

.fiveColumn {
  @apply gap-[20px] grid grid-cols-5;
}

.budgetContainer {
  @apply flex flex-col justify-between;
}

// Read only style
.readOnlyField {
  @apply flex flex-col;
}

.label {
  @apply mb-[10px] text-12-400-normal text-primary-neutral-400 truncate capitalize;
}

.value {
  @apply flex items-center text-primary-zinc-800 text-12-400-15 truncate;
}

// Budget
.budgetSection {
  @apply mt-[10px] flex flex-col;
}

.titleBudget {
  @apply mb-[12px] text-14-500-normal text-primary-zinc-800;
}

.line {
  @apply w-full bg-gray-300 h-[1px];
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.iconAddField {
  @apply object-cover;
}

.btnRemoveField,
.iconAddField {
  @apply w-4 h-4;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

// Form list
.formListGroup {
  @apply flex flex-col gap-y-5 pt-5;
}

.formListSubtitle {
  @apply mt-3 mb-5 text-12-500-normal text-primary-zinc-800 truncate capitalize;
}

.formListGroupSubcontractor {
  @apply flex flex-col gap-y-5;
}

// Field list
.fieldContainer {
  @apply w-full flex justify-between gap-[10px];
}

.fieldContent {
  @apply flex-1 gap-x-5 grid grid-cols-8;
}

.iconAddField {
  @apply w-4 h-4;
}

.iconAddFile {
  @apply w-3 h-3 object-cover;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.btnAddContainer {
  @apply flex items-center gap-x-1;
}

.formSetContractItem {
  @apply w-fit text-12-400-normal text-primary-zinc-500 cursor-pointer inline-block select-none;
  @apply hover:text-primary-cyan-600;
  @apply transition-all duration-75;
}

.workOrder {
  @apply flex gap-2 w-[100px];
}

.labelWorkOrder {
  @apply cursor-pointer text-12-400-normal text-primary-neutral-400;
}

.iconWorkOrder {
  @apply size-[15px] cursor-pointer;
}
