.tabContainer {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tabHead {
  @apply my-6;
}

.tabContent {
  @apply h-full flex flex-col justify-between overflow-hidden;
}

.tabTitle {
  @apply text-14-500-17 text-primary-zinc-800;
}

.textCell {
  @apply text-12-400-15 text-primary-neutral-700;
}

.textColorCell {
  @apply text-12-400-15 text-primary-cyan-600;
}

.date {
  @apply flex items-center gap-2;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.pagePagination {
  @apply my-4;
}
