.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.image {
  @apply w-6 h-6 rounded;
}

.date {
  @apply flex items-center gap-2;
}

.pagePagination {
  @apply mt-6;
}

.textBase {
  @apply text-primary-neutral-700 font-inter text-12-400-15;
}

.date {
  @apply flex items-center gap-2;
}
