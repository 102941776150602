.container {
  @apply w-full;
}

.fileUpload {
  @apply w-fit h-full flex items-center justify-center px-2 text-12-500-normal text-primary-cyan-600 cursor-pointer;
}

.fileInput {
  @apply hidden;
}

.boxInput {
  @apply min-h-[31px] h-[31px] flex items-center  justify-between;
}

.label {
  @apply text-12-400-normal text-primary-zinc-500;
}

.errorMessage {
  @apply min-h-4 text-10-400-normal text-primary-red-500 line-clamp-2 break-words;
}

.iconUpload {
  @apply w-4 h-4;
}

.required {
  @apply text-sunset-orange-500;
}
