.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.buttonAction {
  @apply flex items-center justify-end mb-[18px];
}

.statisticTable {
  @apply flex-1 overflow-y-hidden z-0;
}

.paginationTable {
  @apply flex items-center justify-center mt-6;
}

.actionTable {
  @apply cursor-pointer;
}

.clientTable {
  @apply flex items-center gap-2;
}

.titleQuickBook {
  @apply flex items-center justify-center;
}

.quickBookWrap {
  @apply flex items-center justify-center;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-3xl;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-3xl;
}

.tooltipWrap {
  @apply z-10 flex flex-1 flex-col;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-gray-400;
}
