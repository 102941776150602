.container {
  @apply h-[26px] flex items-center relative;
}

.eventAssigneeContainer {
  @apply min-w-6 w-[26px] h-[26px] flex justify-center items-center absolute top-0 left-0 rounded-full;
}

.eventAssignee {
  @apply border-[2px] w-full h-full rounded-full;
}

.imageAssignee {
  @apply object-cover w-full h-full rounded-full;
}

.eventAssigneeMoreContainer {
  @apply min-w-6 w-[26px] h-[26px] flex justify-center items-center absolute top-0 left-0 rounded-full z-[2];
  @apply before:content-[''] before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  @apply before:rounded-full before:bg-black;
  @apply before:w-[22px] before:h-[22px];
}

.eventAssigneeMore {
  @apply text-12-500-normal text-white flex justify-center items-center;
}

.tooltipWrap {
  @apply z-10 bg-black flex flex-1 flex-col opacity-100 rounded-[6px] right-7 !important;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-white;
}
