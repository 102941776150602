.ql-container.ql-snow {
  @apply border-0 p-0;
}

.ql-editor {
  @apply p-0 text-12-400-15 text-primary-zinc-800 font-inter;
}

.ql-toolbar {
  @apply flex flex-row justify-between;
}

.ql-toolbar.ql-snow {
  @apply border-0 p-0 h-[31px] flex items-center mt-4;
}

.ql-editor.ql-blank::before {
  @apply left-0;
}

.ql-font {
  @apply border-primary-gray-300 border rounded-[5px];
}

.ql-snow .ql-picker.ql-font {
  @apply w-[107px] h-[31px];
}

.ql-toolbar.ql-snow .ql-picker-label {
  @apply flex items-center;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'inter';
}

.ql-snow .ql-picker {
  @apply text-14-400-normal text-primary-zinc-800;
}
