.receivedNotification {
  @apply relative;
}

.receivedNotificationBell {
  @apply relative w-10 h-10 rounded-full flex items-center justify-center cursor-pointer focus:outline-none;
}

.receivedNotificationNumberWrap {
  @apply absolute min-w-5 px-1 h-4 rounded-full bg-primary-red-600 top-0 left-full -ml-5 flex justify-center items-center leading-4;
}

.receivedNotificationNumber {
  @apply text-12-400-15 text-white flex justify-center items-center font-inter;
}

.receivedNotificationContainer {
  @apply z-50 relative bg-white rounded-[10px] shadow-[0_20px_40px_0_rgba(0,15,18,0.1)] p-[20px] pb-[13px] w-[410px] border-primary-neutral-100 border;
  @apply data-[closed]:scale-95 data-[closed]:opacity-0;
  @apply transition duration-200 ease-in-out;
  overflow: visible !important;
}

// Scroll bar
.receivedNotificationContainer {
  :global {
    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply rounded-full bg-primary-slate-300;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full bg-primary-slate-100;
    }
  }
}
