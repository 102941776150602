.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.bodyContent {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg px-6 pt-6 py-4 overflow-hidden;
}

.form {
  @apply w-full h-full overflow-hidden flex flex-col gap-4;
}

.formBody {
  @apply flex-1 overflow-y-auto;
}

.profileAvatarContainer {
  @apply mb-12;
}

.profileAvatar {
  @apply flex items-center gap-5 flex-wrap;
}

.avatar {
  @apply w-[120px] h-[120px] rounded-full object-cover block bg-teal-600 select-none;
}

.avatarActions {
  @apply flex flex-col gap-2;
}

.titleGroup {
  @apply flex items-center mb-5;
}

.titleIcon {
  @apply w-6 h-6 object-cover block select-none mr-2;
}

.title {
  @apply text-17-600-normal text-primary-neutral-700;
}

.btn {
  @apply h-[31px] px-3 py-2 rounded-[5px] text-12-500-15 flex items-center justify-center outline-none cursor-pointer select-none;
}

.changeAvatar {
  @apply bg-primary-cyan-600 text-white border border-primary-cyan-600;
}

.inputChangeAvatar {
  @apply hidden;
}

.deleteAvatar {
  @apply bg-primary-red-600/10 text-primary-red-600 border border-primary-red-600/15;
}

.informationBody {
  @apply grid grid-cols-2 gap-5;
}

.formFooter {
  @apply flex items-center justify-end gap-3;
}

.errMessage {
  @apply mt-3 text-10-400-normal text-primary-rose-500;
}
