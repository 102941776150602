.ql-toolbar {
  @apply flex flex-row justify-between;
}

.ql-toolbar.ql-snow {
  @apply h-[40px] flex items-center border-b-[1px] mb-2 mt-0;
}

.ql-container.ql-snow {
  @apply overflow-hidden;
}

.ql-bubble .ql-editor a {
  @apply text-blue-500;
}
