.container {
  @apply w-full h-full flex flex-1;
}

.quillEditorGroup {
  @apply flex-1 flex flex-col w-full h-full border-primary-gray-300;
}

.quill {
  @apply flex flex-col flex-1 overflow-hidden;
}

.error-text {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
