.notificationContainer {
  @apply bg-white p-[12px] rounded-[6px] flex gap-3 cursor-pointer border border-gray-300;
  @apply hover:bg-neutral-100/60;
}

.notificationImage {
  @apply w-[24px] h-[24px];
}

.notificationImage img {
  @apply block w-full h-full object-cover;
}

.notificationContentBox {
  @apply flex-1;
}

.notificationContent {
  @apply w-full;
}

.notificationTitleBox {
  @apply flex justify-between items-center gap-1 mb-[4px];
}

.notificationTitle {
  @apply text-primary-zinc-800 text-16-500-24 line-clamp-1 flex-1;
}

.notificationTime {
  @apply text-primary-zinc-400 text-12-400-18 line-clamp-1;
}

.notificationDescription {
  @apply text-14-400-24 text-primary-neutral-400 line-clamp-2;
}

.unRead {
  @apply bg-primary-cyan-600/10;
}
