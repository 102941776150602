.container {
  @apply w-full h-full overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.basicInformationWrap {
  @apply flex flex-col gap-9;
}

.titleGroup {
  @apply text-14-500-normal text-primary-zinc-800 pb-4 border-b mb-5;
  border-color: rgba(9, 9, 11, 0.05);
}

.vendorDetailBody {
  @apply flex flex-col gap-4;
}

.contact {
  @apply flex flex-col gap-4;
}

.contactHeader {
  @apply grid grid-cols-8 gap-5 border-b pb-3 border-primary-woodsmoke-940/5;
}

.titleContactHeader {
  @apply col-span-2 text-14-400-normal text-primary-neutral-400;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.additionalContactDetailItem {
  @apply grid gap-5 grid-cols-8 border-b pb-4 border-primary-woodsmoke-940/5;
}

.titleContact {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-2;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-full;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-full;
}
