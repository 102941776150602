.container {
  @apply w-full h-full flex flex-col pr-2 overflow-y-auto justify-between;
}

.container::-webkit-scrollbar {
  @apply w-1;
}

.container::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.container::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.input {
  @apply grid grid-cols-2 gap-[20px];
}

.upperContent {
  @apply mt-5;
}

.textFormTerm {
  @apply flex flex-col items-center justify-center my-5 py-3 gap-y-[30px];
}

.label {
  @apply text-center;
}

.btnGroup {
  @apply flex items-center justify-end gap-3 pt-[30px];
}
