.container {
  @apply flex flex-col;
}

.fieldControl {
  @apply w-full flex justify-between items-center text-primary-zinc-800 text-12-400-15 px-2 rounded-[5px] border border-primary-gray-300;
}

.valueField {
  @apply flex-1 text-start;
}

.placeholderText {
  @apply text-primary-zinc-500 text-12-400-normal;
}

.icon {
  @apply w-[15px] h-[15px];
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
