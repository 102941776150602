.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-zinc-500 truncate capitalize;
}

.required {
  @apply text-sunset-orange-500;
}

// Input
.inputContainer {
  @apply relative flex items-center gap-x-2 w-full;
  @apply border border-primary-gray-300 rounded-[6px];
  @apply px-2 py-[7px];
}

.valueGroup {
  @apply flex-1 overflow-hidden;
  @apply flex flex-wrap gap-[6px];
}

.mainInput {
  @apply flex-1 outline-none;
  @apply text-primary-zinc-800 text-12-400-15 truncate;
  @apply placeholder:text-12-400-15 placeholder:text-primary-zinc-500;
}

.mainInput:disabled {
  @apply bg-white;
}

// Tags
.tagContainer {
  @apply rounded-2xl inline-flex items-center gap-x-1 p-[6px] bg-primary-neutral-200 max-w-full;
}

.tagName {
  @apply text-primary-zinc-800 text-12-400-15 px-1 truncate;
}

// Icon Remove
.btnRemove {
  @apply min-h-4 min-w-4 size-4 p-1 bg-primary-neutral-100 rounded-full cursor-pointer;
  @apply hover:bg-primary-neutral-400/35;
  @apply transition-all duration-300;
}

.iconRemove {
  @apply w-full h-full object-cover;
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
