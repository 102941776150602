.modalContainer {
  @apply fixed bg-black top-0 bottom-0 left-0 right-0 z-10 bg-opacity-25;
}

.modalContent {
  @apply w-[342px] pt-[44px] pl-[47px] pr-[47px] pb-[28px] bg-white border absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-10 rounded-[5px] border-primary-gray-300 shadow-[0px_0px_12px_4px_rgba(0,0,0,0.12)];
}

.modalHead {
  @apply flex;
}

.titleHead {
  @apply w-full text-14-500-normal text-neutral-950 text-center;
}

.modalBody {
  @apply flex flex-row gap-[16px] mt-[56px] justify-center items-center;
}
