.baseTableComponent {
  @apply w-full h-full flex-1 bg-white overflow-auto relative;
}

.tableContainer {
  @apply w-full text-left table-auto;
}

.colTable {
  @apply py-4 text-left text-14-400-17 text-primary-neutral-400 font-inter z-[2] sticky top-0 bg-white border-transparent;
}

.colTable::after {
  @apply content-[""] absolute left-0 bottom-0 w-full border-b-[1px] border-b-primary-gray-300;
}

.colTableBody {
  @apply py-2 text-12-400-15 text-primary-zinc-900 break-words whitespace-normal;
}

.rowTableBody {
  @apply hover:bg-primary-gray-300/15;
}

.rowTableBody.rowClickable {
  @apply cursor-pointer;
}

// Custom Table colorTable
.baseTableComponent.colorTable {
  @apply overflow-y-scroll;

  &::-webkit-scrollbar-thumb:horizontal {
    @apply rounded-full bg-primary-slate-300 cursor-pointer;
  }

  &::-webkit-scrollbar-track:horizontal {
    @apply rounded-full bg-primary-slate-100;
  }
}

.thead.colorTable {
  @apply border-b-0;
}

.colTable.colorTable {
  @apply pl-8 border-0 text-left text-14-400-17 text-primary-neutral-400 bg-none font-inter sticky top-0 z-[2];
}

.colTable.colorTable::after {
  @apply content-[""] absolute h-full border-0 bg-primary-gray-300 bg-opacity-[35%] px-0 -z-10;
}

.colTable.colorTable:first-child::after {
  @apply rounded-tl-[5px];
}

.colTable.colorTable:last-child::after {
  @apply rounded-tr-[5px];
}

.colTableBody.colorTable {
  @apply py-[17px] pl-[32px] text-12-400-15 text-primary-zinc-900 border-b bg-primary-gray-300 bg-opacity-[10%] break-words whitespace-normal truncate pr-2;
}

.noDataAvailable {
  @apply text-14-400-normal text-primary-zinc-800 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
}
