.container {
  @apply w-full;
}

.fileName {
  @apply flex-1 my-auto pl-2 text-12-500-normal text-primary-zinc-900 truncate;
}

.fileUpload {
  @apply w-fit h-full flex items-center justify-center px-2 text-12-500-normal text-primary-cyan-600 cursor-pointer;
}

.fileInput {
  @apply hidden;
}

.boxInput {
  @apply min-h-[31px] h-[31px] border rounded-[5px] border-primary-gray-300 flex items-center justify-between;
}

.label {
  @apply mb-2 text-12-400-normal text-primary-zinc-500;
}

.viewStar {
  @apply text-primary-rose-500;
}

.errorMessage {
  @apply min-h-4 text-10-400-normal text-primary-red-500 px-2 mt-1 line-clamp-2 break-words;
}

.linkFile {
  @apply inline-block w-full text-12-400-normal text-primary-green-600 line-clamp-2 break-all;
}

.boxView {
  @apply flex items-center;
}

.fileName.placeholder {
  @apply text-12-500-normal text-primary-zinc-500;
}
