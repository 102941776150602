.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[672px] max-h-[80%] bg-white p-[30px] rounded-lg flex flex-col;
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.title {
  @apply text-20-700-normal text-black truncate pb-5 border-b border-primary-gray-300 mb-[30px];
}

.body {
  @apply flex-1 overflow-y-auto;
}

.body::-webkit-scrollbar {
  @apply hidden;
}

.timeClockDetailsSection {
  @apply pb-6 border-b border-b-primary-gray-300 mb-6;
}

.groupItem {
  @apply grid grid-cols-[130px_1fr] gap-5 mb-5 last:mb-0;
}

.item {
  @apply text-14-400-normal text-wrap;
}

.item.key {
  @apply text-primary-neutral-400;
}

.item.val {
  @apply text-primary-zinc-800;
}

.avatarGroup {
  @apply flex items-center gap-2;
}

.locationSection {
  @apply mb-6;
}

.locationTitle {
  @apply text-17-600-normal text-primary-zinc-800 mb-3;
}

.locationVal {
  @apply text-14-400-normal text-primary-zinc-500 text-wrap;
}

.mapSection {
  @apply rounded-md overflow-hidden;
}
