.modalContainer {
  @apply fixed bg-black top-0 bottom-0 left-0 right-0 z-[9999] bg-opacity-25;
}

.modalContent {
  @apply w-[400px] bg-white border rounded-[5px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-visible max-h-[80%] flex flex-col;
}

.formButtonWrap {
  @apply flex flex-row justify-center items-center w-full pr-[35px] pl-8 mt-[55px] mb-[24px];
}

.buttonGroup {
  @apply flex flex-row justify-center items-center gap-6;
}

.bodyContent {
  @apply mt-[32px] flex justify-center items-center;
}

.textDevelopment {
  @apply text-primary-zinc-500;
}
