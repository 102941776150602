.addAssigneeContainer {
  @apply fixed w-[350px] z-[1000] h-[1500px];
}

.container {
  @apply max-h-[20%] mt-2 bg-white p-4 overflow-hidden flex flex-col rounded-lg;
  @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.assignee {
  @apply flex flex-col gap-6 overflow-y-scroll mt-5;
}

.viewStar {
  @apply text-primary-rose-500;
}

.assigneeItem {
  @apply flex items-center justify-between;
}

.active {
  @apply text-14-400-17 text-green-800;
}

.avatarFirstLastName {
  @apply flex items-center gap-[10px] text-12-400-normal;
}

.firstLastNameStatus {
  @apply flex flex-col gap-1;
}

.firstLastName {
  @apply truncate;
}

.inactive {
  @apply text-gray-500 text-14-400-17;
}

.titleSelect {
  @apply text-12-400-normal text-primary-zinc-500 mb-[10px];
}

.selectAssign {
  @apply w-full border rounded-[8px] h-[35px] flex flex-row  px-[10px] border-gray-300 justify-between items-center;
}

.titleAssignee {
  @apply text-12-400-normal text-zinc-500;
}

.iconDown {
  @apply w-[14px] h-[14px];
}

.assigneeSelected {
  @apply flex flex-row items-center gap-1 mt-[11px];
}

.tagAssigneeSelected {
  @apply flex flex-row items-center min-w-[71px] border-[1px] rounded-[100px] h-5 pl-[2px] pr-1 gap-1;
}

.iconCloseAssignee {
  @apply w-[6px] h-[6px] ml-auto;
}

.tagFirstName {
  @apply text-12-400-15;
}

.tagMore {
  @apply text-12-400-15;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
