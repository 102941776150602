.container {
  @apply w-full h-full flex flex-1;
}

.quillEditorGroup {
  @apply flex-1 flex flex-col w-full border-primary-gray-300 border rounded-[5px] px-6 py-4;
}

.quillEditor {
  @apply flex flex-col flex-1 overflow-hidden;
}
