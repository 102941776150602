.basePaginationContainer {
  @apply w-full flex justify-center items-center gap-x-3;
}

.pageButton {
  @apply flex justify-center items-center w-8 h-8 rounded-[10px] bg-primary-gray-300 font-inter text-14-400-normal text-primary-stone-800;
}

.pageButton.disabled {
  @apply opacity-50 pointer-events-none cursor-default;
}

.threeDotStyle {
  @apply text-primary-gray-400 text-10-400-normal;
}

.active {
  @apply text-white bg-primary-cyan-600;
}

.leftChevronIcon {
  @apply rotate-180;
}
