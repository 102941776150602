.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px];
}

.body {
  @apply border h-full w-full flex flex-col overflow-hidden rounded-lg mr-[14px] p-6;
}

.content {
  @apply w-full flex-1 overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.invoiceDetailWrap {
  @apply w-full flex flex-col gap-4;
}

.invoiceDetailTitle {
  @apply text-14-500-normal text-primary-zinc-800 mb-2;
}

.invoiceDetailContent {
  @apply flex gap-10;
}

.invoiceDetail {
  @apply flex-1 flex flex-col gap-4;
}

.lineItemsWrap,
.totalWrap {
  @apply mt-12;
}

.lineItems {
  @apply flex flex-col gap-4;
}

.titleGroup {
  @apply text-14-500-normal text-primary-zinc-800 pb-4 border-b mb-5;
  border-color: rgba(9, 9, 11, 0.05);
}

.lineItems {
  @apply flex flex-col gap-4;
}

.lineItemsHeader {
  @apply grid grid-cols-8 gap-5 border-b pb-3 border-primary-woodsmoke-940/5;
}

.titleLineItemsHeader {
  @apply col-span-2 text-14-400-normal text-primary-neutral-400;
}

.titleLineItemsHeader.items {
  @apply col-span-6;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.lineItemsBody {
  @apply grid gap-5 grid-cols-8 border-b pb-4 border-primary-woodsmoke-940/5;
}

.titleLineItemsBody {
  @apply text-14-400-17 text-primary-zinc-800 truncate col-span-2;
}

.titleLineItemsBody.items {
  @apply col-span-6;
}

.totalWrap {
  @apply grid grid-cols-8;
}

.totalInvoice {
  @apply flex flex-col gap-4 col-start-6 col-span-3;
}

.avatarFirstLastName {
  @apply flex items-center gap-2;
}
