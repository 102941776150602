.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-zinc-500 capitalize;
}

.viewStar {
  @apply text-primary-rose-500;
}

// Select
.btnSelect {
  @apply flex justify-between items-center gap-x-1 p-[10px] bg-white border border-primary-gray-300 rounded-[6px];
  @apply data-[disabled]:bg-primary-stone-50;
}

.btnPlaceholder {
  @apply text-12-400-normal truncate text-primary-zinc-500 capitalize;
}

.btnText {
  @apply text-12-400-15 text-primary-zinc-800 truncate;
}

.iconActive {
  @apply rotate-180;
}

// Option
.optionList {
  @apply z-50 rounded-lg border border-primary-seashell-100 bg-white p-1;
  @apply w-[var(--button-width)] [--anchor-max-height:150px];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.optionList::-webkit-scrollbar {
  @apply w-1;
}

.optionList::-webkit-scrollbar-thumb {
  @apply bg-primary-slate-300 rounded-full;
}

.optionList::-webkit-scrollbar-track {
  @apply rounded-full;
}

.optionItem {
  @apply w-full p-[8px] text-12-400-15 text-primary-zinc-900 cursor-pointer rounded-lg mt-[2px];
  @apply hover:bg-primary-cyan-600/10 hover:text-primary-cyan-600;
}

.optionActive {
  @apply bg-primary-cyan-600 text-white;
  @apply hover:bg-primary-cyan-600 hover:text-white;
}

// No data
.optionNoData {
  @apply w-full p-[8px] text-12-400-15 text-primary-zinc-500 rounded-lg mt-[2px];
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
