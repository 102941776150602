.container {
  @apply fixed inset-0 bg-black/25 z-10;
  @apply w-full flex items-center justify-center;
}

.modal {
  @apply relative flex flex-col w-full max-h-[80vh] max-w-[800px] rounded-lg bg-white p-[30px];
  @apply transition duration-300 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
}

.header {
  @apply text-20-700-normal text-primary-zinc-800 mb-5;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.description {
  @apply text-14-500-normal text-common-858C8A mb-3;
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-[20px] pr-2;
}

// Scroll bar
.modalBody::-webkit-scrollbar {
  @apply w-1;
}

.modalBody::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.modalBody::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.modalFooter {
  @apply flex items-center justify-end gap-3 mt-[30px];
}
