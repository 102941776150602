.container {
  @apply w-full h-full flex flex-col overflow-y-auto;
}

// Title
.title {
  @apply text-18-400-normal text-primary-zinc-800;
}

// Content
.formContent {
  @apply flex-1 w-full mt-[10px];
}

// Budget
.budgetHeader {
  @apply z-[2] sticky top-0 bg-white pb-4;
}

.header {
  @apply flex justify-between items-center;
}

.budgetContainer {
  @apply w-full border border-primary-gray-300 p-5 rounded-lg mt-4;
}

.budgetTitle {
  @apply text-12-500-normal text-primary-zinc-800;
}

.budgetNumbersGroup {
  @apply mt-4 flex items-center flex-wrap gap-x-10 gap-y-2;
}

.budgetNumberItem {
  @apply flex items-center gap-x-3 text-14-400-normal;
}

.budgetNumberLabel {
  @apply text-primary-neutral-400;
}

.budgetNumberValue {
  @apply text-primary-zinc-800;
}

// Phase content
.phaseGroup {
  @apply mb-[30px] flex flex-col gap-y-5;
}

// Accordion
.accordionContainer {
  @apply border border-primary-gray-300 p-5 rounded-lg mb-4;
}

.accordionBtnContainer {
  @apply flex w-full justify-between items-center;
}

.accordionBtnWrap {
  @apply flex gap-x-2 items-center;
}

.accordionPhaseName {
  @apply text-14-500-normal text-primary-zinc-800;
}

.accordionIconArrow {
  @apply size-5 -rotate-90 fill-white/60 group-data-[open]:rotate-0 transition;
}

.accordionActionWrap {
  @apply flex gap-x-2 items-center;
}

.accordionPanel {
  @apply mt-4 flex flex-col gap-y-[30px];
  @apply transition duration-200 ease-out data-[closed]:-translate-y-1 data-[closed]:opacity-0;
}

// Btn add item
.btnAddItem {
  @apply flex items-center gap-x-1;
}

.iconAddItem {
  @apply w-4 h-4;
}

.btnTextItem {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.saveTemplate {
  @apply mt-5;
}

// Footer
.btnGroup {
  @apply flex items-center justify-end gap-3;
}

// Error message
.errorMessagePhase {
  @apply mb-3 text-12-500-normal text-primary-rose-500;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.iconAddFile {
  @apply w-3 h-3 object-cover;
}

.iconAddField {
  @apply w-4 h-4;
}

.iconAddField {
  @apply object-cover;
}
