.modalContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px] font-inter;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-[20px] pb-2 pr-2;
}

.modalBody::-webkit-scrollbar {
  @apply w-1;
}

.modalBody::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.modalBody::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.inputContent {
  @apply gap-[20px] grid grid-cols-2;
}

.modalFooter {
  @apply flex gap-3 pt-[30px] justify-end;
}

.mapSection {
  @apply rounded-md;
}

.textLabel {
  @apply mb-[10px] text-12-400-normal text-primary-neutral-400 truncate capitalize;
}
