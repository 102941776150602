.container {
  @apply relative;
}

.label {
  @apply text-12-500-normal text-primary-zinc-500 truncate;
}

.viewStar {
  @apply text-primary-rose-500;
}

.selectMenu {
  @apply relative w-[117px];
}

.selectBtn {
  @apply h-[31px] px-2 rounded-[5px] border border-primary-gray-300 flex items-center justify-between gap-2 cursor-pointer select-none bg-white;
}

.value {
  @apply flex-1 text-12-500-normal text-primary-zinc-500 truncate;
}

.options {
  @apply absolute top-full left-0 w-full mt-1 rounded-[5px] border border-primary-gray-300 bg-white overflow-hidden z-10;
}

.listOption {
  @apply w-full max-h-[140px] overflow-y-auto;
}

.openUpwards {
  @apply bottom-full top-auto mb-1;
}

.listOption::-webkit-scrollbar {
  @apply hidden;
}

.option {
  @apply min-h-8 p-2 text-12-500-normal text-primary-zinc-500 border-b border-b-primary-gray-300 cursor-pointer;
}

.option:hover {
  @apply p-2 text-12-500-normal text-primary-zinc-500 border-b border-b-primary-gray-300 bg-primary-cyan-600/10 text-primary-cyan-600 cursor-pointer;
}

.option:last-child {
  @apply border-b-0;
}

.option.active {
  @apply bg-primary-cyan-600 text-white;
}

.icon {
  @apply transition-all;
}

.iconActive {
  @apply rotate-180;
}

.option.noData {
  @apply cursor-default;
}

.errorMessage {
  @apply min-h-4 text-12-500-15 text-primary-red-500 px-2 mt-1 line-clamp-2 break-words;
}

.container.disabled .selectBtn {
  @apply cursor-default;
}
