.container {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.body {
  @apply flex-1 mt-4 p-6 border border-primary-gray-300 rounded-lg flex flex-col overflow-hidden;
}

.tabContent {
  @apply flex-1 mt-6 overflow-hidden;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between;
}

.label {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}
