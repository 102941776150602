/* Spinner.css */
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.spinner {
  border: 2px solid rgba(39, 167, 69, 0.1);
  border-left-color: #27a745;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
