.managementEmployeeContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply border h-full w-full flex flex-col overflow-hidden rounded-lg mr-[14px] px-5;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.buttonAction {
  @apply flex justify-end mt-2 mr-4 mb-4;
}

.avatarUrl {
  @apply h-[28px] w-[28px] object-cover rounded-full;
}

.avatarFirstLastName,
.date {
  @apply flex items-center gap-2;
}

.paginationTable {
  @apply mt-4 mb-4;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}
