.manageProposalContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.proposalHeader {
  @apply flex flex-row justify-between mb-4;
}

.manageProposalBody {
  @apply h-full flex flex-col justify-between overflow-hidden;
}

.avatarFirstLastName,
.textCell,
.currency,
.date {
  @apply flex items-center gap-2 text-primary-zinc-800;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden mt-1 z-0;
}

.pagePagination {
  @apply my-4;
}
