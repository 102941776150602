.modalContent {
  @apply flex flex-1 flex-col overflow-hidden;
}

.modalHeader {
  @apply text-20-700-normal text-primary-zinc-800 mb-5;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-[20px] pb-2 pr-2;
}

.modalBody::-webkit-scrollbar {
  @apply w-1;
}

.modalBody::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.modalBody::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.mainContent {
  @apply flex justify-between gap-2;
}

.formSection {
  @apply w-[460px] flex flex-col gap-y-3;
}

.presentPdfSection {
  @apply flex-1;
}

.modalFooter {
  @apply flex gap-3 pt-[30px] justify-end;
}
