.container {
  @apply relative w-full min-h-screen flex;
}

.logoSection {
  @apply relative shrink-0 overflow-hidden;
  @apply basis-0 xl:basis-[60%];
  @apply transition-all ease-in-out duration-300;
}

.authBackgroundImage {
  @apply absolute top-0 left-0 w-full h-full bg-auth bg-right bg-no-repeat bg-cover mix-blend-multiply;
}

.logo {
  @apply cursor-pointer;
}

.images {
  @apply w-[337px] h-[60px];
}

.body {
  @apply w-[679px] px-20 h-full flex flex-col bg-white overflow-hidden relative justify-center;
}

.body.bodySuccess {
  @apply bg-white;
}

.bodyAuthWrap {
  @apply flex-1 flex items-center justify-center w-[40%];
}

.textVersion {
  @apply absolute right-3 bottom-3 text-14-400-17 text-primary-zinc-800;
}

.content {
  @apply w-full min-w-[320px] py-8 flex items-center justify-center overflow-hidden;
  @apply transition-all ease-in-out duration-300;
}
