.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-stone-500 capitalize;
}

.uploadImgContainer {
  @apply w-full flex items-center gap-[10px];
}

// Img Preview
.imgPreviewWrap {
  @apply relative;
}

.imgPreview {
  @apply w-[35px] h-[35px] rounded-lg object-cover;
}

.iconClose {
  @apply absolute -top-[3px] -right-[3px] cursor-pointer bg-primary-stone-50 p-1 rounded-full;
}

// Btn Upload
.btnUpload {
  @apply flex-1 flex justify-center items-center gap-[6px];
  @apply rounded-lg bg-primary-cyan-600/5 p-[10px] hover:bg-primary-cyan-600/10;
  @apply text-12-500-normal text-primary-cyan-600  truncate;
  @apply border border-dashed border-primary-cyan-600 cursor-pointer;
}

.inputUpload {
  @apply hidden;
}

// Err Message
.errMessage {
  @apply mt-1 text-10 px-1 text-sunset-orange-500;
}
