.inputSearchWrapper {
  @apply relative flex items-center;
}

.inputSearch {
  @apply h-[30px] text-primary-zinc-900 text-12-500-15 font-inter border border-primary-gray-300 outline-none rounded-lg pl-9 pr-2 py-[5px];
}

.inputSearch::placeholder {
  @apply text-primary-zinc-500;
}

.iconInputSearch {
  @apply absolute left-2;
}

.inputSearch.searchAnything {
  @apply h-10 pl-10 pr-2 py-[5px] text-14-400-17;
}

.inputSearch.searchAnything::placeholder {
  @apply text-primary-zinc-500;
}

.inputSearch.withFull {
  @apply w-full;
}

.iconSearch {
  @apply h-5 w-5;
}
