.arrow {
  @apply absolute h-4 bg-[white] rotate-45 z-[-1] -top-2 w-4 left-1/2 border-t border-l border-primary-neutral-100 -translate-x-1/2;
}

.titleGroup {
  @apply w-full flex items-center border-b pb-3 mb-[20px] border-b-gray-300;
}

.notificationTitle {
  @apply text-20-400-normal text-primary-zinc-800 truncate;
}

.receivedNotificationScroll {
  @apply mr-[6px];
}

.notificationGroup {
  @apply mb-[20px];
}

.notificationGroupTitle {
  @apply mb-2 text-14-400-normal text-gray-900 line-clamp-1;
}

.receivedNotificationList {
  @apply flex flex-col gap-2;
}

.noData {
  @apply flex justify-center items-center h-full flex-1;
}
