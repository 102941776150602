.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.buttonAction {
  @apply flex items-center justify-end mb-[18px];
}

.statisticTable {
  @apply flex-1 overflow-y-hidden z-0;
}

.paginationTable {
  @apply flex items-center justify-center mt-6;
}
