.manageScheduleContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px];
}

.body {
  @apply border h-full w-full overflow-hidden flex flex-col rounded-lg mr-[14px] p-6;
}

.toolbarGroup {
  @apply flex justify-between items-center gap-x-4;
}

.toolbarLeft,
.btnControl {
  @apply flex items-center;
}

.dateControlBtn {
  @apply flex items-center justify-center outline-none bg-none w-7 h-7 bg-primary-cyan-600;
}

.btnToday {
  @apply rounded-lg border text-14-500-normal h-7 w-[56px] flex justify-center items-center text-primary-neutral-400 ml-2;
  @apply hover:bg-gray-100/50;
}

.btnPrev {
  @apply rounded-s-lg;
}

.btnNext {
  @apply rounded-e-lg;
}

.timeValue {
  @apply text-sky-950 text-20-500-normal;
}

.scheduleWrap {
  @apply flex-1 overflow-y-auto mt-6;
}

//
.toolbarRight {
  @apply flex items-center gap-x-[8px];
}

.btnGroup {
  @apply bg-gray-100 rounded-lg p-1 flex justify-between items-center gap-1;
}

.btnCalendar {
  @apply bg-none px-3 py-[5px] text-14-400-normal text-primary-neutral-400 rounded-md hover:bg-white/50;
}

.btnCalendar.active {
  @apply bg-white text-primary-cyan-600 text-14-500-normal;
}

.filterGroup {
  @apply mb-3 last:mb-0 flex items-center justify-between gap-5 min-w-[350px] h-[31px];
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between w-[200px] overflow-hidden;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}

.tagAssigneeSelected {
  @apply flex flex-row items-center min-w-[71px] border-[1px] rounded-[100px] h-5 pl-[2px] pr-1 gap-1;
}

.iconCloseAssignee {
  @apply w-[6px] h-[6px] ml-auto shrink-0;
}

.tagFirstName {
  @apply text-12-400-15 truncate;
}

.tagJobName {
  @apply text-12-400-15 truncate pl-[2px];
}

.tagMore {
  @apply text-12-400-15;
}

.avatarFirstLastName {
  @apply flex items-center gap-[10px] text-12-400-normal w-full overflow-hidden;
}

.firstLastName {
  @apply truncate;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}
