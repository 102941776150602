#baseScheduleTimeline {
  height: 100%;
  min-width: 1000px;

  .react-calendar-timeline .rct-dateHeader {
    border-bottom: unset;
    border-left: 1px solid #e7e7e7;
    background-color: unset;
  }

  .react-calendar-timeline .rct-calendar-header {
    border: unset;
  }

  .react-calendar-timeline .rct-header-root {
    background: rgba(212, 223, 227, 0.3);
    border-bottom: unset;
  }

  .react-calendar-timeline .rct-sidebar {
    border-right: unset;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: unset;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: unset;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: unset;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 1px;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid #e7e7e7;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    background: #fbfbfb;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: #fbfbfb;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid #e7e7e7;
  }
}
