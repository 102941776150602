.textareaContainer {
  @apply flex flex-col h-full;
}

.textareaLabel {
  @apply w-fit mb-[10px] text-12-400-normal text-primary-zinc-500 truncate capitalize;
}

.textareaContent {
  @apply bg-white flex justify-center items-center outline-0 rounded-lg border border-primary-gray-300 w-full h-full py-[10px];
}

.textarea {
  @apply outline-0 text-primary-zinc-800 text-12-400-normal w-full px-[10px] rounded-lg resize-none;
}

.textarea::placeholder {
  @apply text-primary-zinc-500 text-12-400-normal;
}

// Error Message
.textareaError {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}

.required {
  @apply text-sunset-orange-500;
}
