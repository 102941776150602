.label {
  @apply block w-fit pb-[10px] text-12-400-normal text-primary-zinc-500 truncate;
}

.required {
  @apply text-primary-rose-500;
}

.phoneNumberWrap {
  @apply w-full h-[35px] flex items-center border border-primary-gray-300 rounded-[6px] overflow-hidden;
}

.inputPhone {
  @apply flex-1 h-full px-2 py-[7px] outline-none text-primary-zinc-800 text-12-400-15 truncate;
  @apply placeholder:text-12-400-15 placeholder:text-primary-zinc-500;
  @apply data-[disabled]:bg-primary-stone-50;
}

.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
