.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.tableSection {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg px-5 overflow-hidden;
}

.table {
  @apply flex-1 overflow-hidden z-0;
}

.actions {
  @apply mb-[17px];
}

.pagination {
  @apply my-4 flex items-center justify-center;
}

.tableLink {
  @apply text-12-400-normal text-primary-cyan-600 underline;
}
