.container {
  height: 100%;
  min-width: 1000px;
  position: relative;
  z-index: 0;
}

// Events
.monthEventWrap {
  @apply max-h-[95px] overflow-x-hidden overflow-y-auto flex flex-col gap-[4px] pr-1;
}

.eventContainer {
  @apply h-full w-full rounded relative border border-solid z-[2];
  @apply before:content-[''] before:absolute before:-inset-[1px] before:opacity-[0.08] before:z-[-1] before:pointer-events-none;
  @apply before:rounded before:border before:border-solid before:border-black;
}

.eventContainerCollapse {
  @apply px-[10px];
}

.eventContainerExpand {
  @apply p-[6px] flex flex-col h-[95px];
}

// Scroll bar
.monthEventWrap::-webkit-scrollbar {
  @apply w-1;
}

.monthEventWrap::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.monthEventWrap::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}

.eventName {
  @apply truncate text-12-400-normal text-primary-zinc-800;
}

.eventTime {
  @apply truncate mt-[2px] text-10-400-normal text-primary-zinc-500;
}

.assigneeContainer {
  @apply mt-auto;
}
