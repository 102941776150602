.container {
  @apply w-full;
}

.twoCol {
  @apply grid grid-cols-2 gap-5 min-h-10;
}

.fiveCol {
  @apply grid grid-cols-[1fr_1fr_1fr_1.5fr_auto] gap-5;
}

.removeItemWrap {
  @apply h-full flex items-end justify-end;
}

.removeItem {
  @apply w-4 h-[35px] flex items-center justify-center;
}

.removeItemImg {
  @apply w-4 h-4 object-cover cursor-pointer;
}

.itemLabel {
  @apply mb-[10px] text-12-400-normal text-primary-zinc-500 truncate capitalize text-right;
}

.itemValue {
  @apply h-[35px] flex items-center justify-end text-primary-zinc-800 text-12-400-15 truncate;
}
