.manageNoteContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-4 font-inter;
}

.noteContent {
  @apply h-full flex flex-col justify-between overflow-hidden;
}

// Title
.manageTile {
  @apply my-[24px] w-10 h-4 text-14-500-17 text-primary-zinc-800;
}

// Table
.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.textColor {
  @apply text-12-400-15 text-primary-neutral-700;
}

.colorTitle {
  @apply text-primary-cyan-600;
}

.avatarUrl {
  @apply h-[28px] w-[28px] object-cover rounded-full;
}

.avatarFirstLastName,
.date {
  @apply flex items-center gap-2;
}

.pagePagination {
  @apply my-4;
}
