.container {
  @apply w-full h-full;
}

.dateGroup {
  @apply flex justify-center items-center w-[96px] truncate rounded-[5px] p-2;
  @apply bg-primary-cyan-600/10;
  @apply text-primary-cyan-600 text-12-400-normal;
}

// Content
.mainContent {
  @apply w-full flex flex-col gap-y-1;
}

.content {
  @apply text-16-400-normal text-primary-zinc-800 truncate min-h-5;
}

.subContentGroup {
  @apply w-[350px] flex justify-between items-center;
}

.createBy {
  @apply text-primary-neutral-400 text-14-400-normal;
}

.btnDetail {
  @apply text-14-500-normal text-primary-cyan-600;
}

.timeLog {
  @apply text-primary-gray-400 text-14-400-21 text-right;
}

.dotLogWrap {
  @apply size-10 rounded-full flex justify-center items-center bg-primary-neutral-400/50 overflow-hidden;
}

.actionIcon {
  @apply w-5 h-5 object-cover block;
}

.created {
  @apply bg-violet-50;
}

.uploaded {
  @apply bg-sky-50;
}

.converted {
  @apply bg-violet-50;
}

.sent {
  @apply bg-sky-100;
}

.changed {
  @apply bg-primary-orange-50;
}

.updated {
  @apply bg-gray-100;
}

.deleted {
  @apply bg-primary-rose-50;
}

// Scroll bar

.container {
  :global {
    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply rounded-full bg-primary-slate-300;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full bg-primary-slate-100;
    }
  }
}
