.container {
  @apply w-full flex justify-between gap-[10px] items-center;
}

.fieldContent {
  @apply flex-1 gap-x-5 grid grid-cols-8;
}

.readOnlyField {
  @apply flex flex-col;
}

.label {
  @apply mb-[10px] text-12-400-normal text-primary-neutral-400 truncate capitalize;
}

.value {
  @apply flex items-center text-primary-zinc-800 text-12-400-15 truncate;
}

.btnRemoveContainer {
  @apply flex items-center justify-center mt-6;
}

.btnRemoveField {
  @apply w-4 h-4;
}
