.container {
  @apply flex flex-col;
}

.options {
  @apply relative flex-1 flex flex-col h-full gap-[2px] pr-2;
}

.viewStar {
  @apply text-primary-rose-500;
}

.btnPlaceholder {
  @apply text-12-400-normal truncate text-primary-zinc-500 capitalize;
}

.btnText {
  @apply text-12-400-15 text-primary-zinc-800 truncate;
}

.optionItem {
  @apply w-full p-[8px] text-12-400-15 text-primary-zinc-900 cursor-pointer rounded-lg mt-[2px];
  @apply hover:bg-primary-cyan-600/10 hover:text-primary-cyan-600;
}

.optionActive {
  @apply bg-primary-cyan-600 text-white;
  @apply hover:bg-primary-cyan-600 hover:text-white;
}

.assigneeItem {
  @apply flex items-center justify-between w-full;
}

.titleSelect {
  @apply text-12-400-normal text-primary-zinc-500 mb-[10px];
}

.btnSelect {
  @apply flex justify-between items-center gap-x-1 p-[10px] bg-white border border-primary-gray-300 rounded-[6px];
  @apply data-[disabled]:bg-primary-stone-50;
}

.labelSelect {
  @apply text-12-400-normal text-zinc-500;
}

.iconDown {
  @apply w-[14px] h-[14px];
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full h-full text-center flex justify-center items-center;
}

.optionList {
  @apply z-[1000] rounded-lg border border-primary-seashell-100 bg-white p-4 flex flex-col;
  overflow: hidden !important;
  @apply w-[var(--button-width)] [--anchor-max-height:50%];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.optionList.optionListSearch {
  :global {
    .infinite-scroll-component {
      @apply mt-2;
    }

    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply rounded-full bg-primary-slate-300;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full bg-primary-slate-100;
    }
  }
}

.optionList {
  :global {
    .infinite-scroll-component {
      @apply mt-0;
    }

    .infinite-scroll-component::-webkit-scrollbar {
      @apply w-1;
    }

    .infinite-scroll-component::-webkit-scrollbar-thumb {
      @apply rounded-full bg-primary-slate-300;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      @apply rounded-full bg-primary-slate-100;
    }
  }
}

// Error Message
.errMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}
