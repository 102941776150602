.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px];
}

.body {
  @apply border h-full w-full flex flex-col overflow-hidden rounded-lg mr-[14px] p-6;
}

.tabContent {
  @apply w-full pt-5 flex-1 overflow-y-auto;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
