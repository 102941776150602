.container {
  @apply flex flex-col overflow-hidden;
}

.header {
  @apply text-20-700-normal text-primary-zinc-800 mb-5;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.form {
  @apply flex-1 flex flex-col overflow-hidden;
}

.body {
  @apply flex-1 grid grid-cols-1 gap-5 overflow-y-auto pr-2;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.groupTitle {
  @apply text-14-500-normal text-primary-zinc-800;
}

.actions {
  @apply flex items-center justify-end gap-3 mt-[30px];
}

// TextEditor
.editorLabel {
  @apply mb-[10px] text-12-400-normal text-primary-zinc-500 truncate capitalize;
}

.editorContent {
  @apply relative border border-primary-gray-300 rounded-[6px] px-2 py-1;
}

// Btn add material
.btnAddField {
  @apply flex items-center gap-x-1;
}

.iconAddField {
  @apply object-cover;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

// Scroll bar
.body::-webkit-scrollbar {
  @apply w-1;
}

.body::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary-slate-300;
}

.body::-webkit-scrollbar-track {
  @apply rounded-full bg-primary-slate-100;
}
