.container {
  @apply inline-block bg-transparent rounded-full p-[2px] overflow-hidden -space-x-[6px];
  @apply flex items-center;
}

.avatarStackItem {
  @apply flex-shrink-0 relative w-6 h-6 rounded-full object-cover border-[1.5px] border-alabaster-50 select-none overflow-hidden;
}

.avatarRemaining {
  @apply text-10-500-normal text-white truncate;
  @apply bg-primary-cyan-600 w-6 h-6 rounded-full border-[1.5px] border-alabaster-50 z-[1] select-none overflow-hidden;
  @apply flex items-center justify-center;
}

.avatarStackPlus {
  @apply w-6 h-6 rounded-full bg-primary-cyan-600 border-[1.5px] border-alabaster-50 cursor-pointer z-[1] select-none overflow-hidden;
  @apply flex items-center justify-center;
  @apply hover:bg-primary-cyan-600/80;
  @apply transition-all ease-out duration-75;
}

.avatarStackIconPlus {
  @apply w-[7px] h-[7px] object-cover;
}

.tooltipWrap {
  @apply z-10 flex flex-1 flex-col opacity-100 !important;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-gray-400;
}
