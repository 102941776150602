.formVendorModalContainer {
  @apply fixed inset-0 bg-black/25 z-10;
}

.formModalVendor {
  @apply flex relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[780px] max-h-[80%] bg-white p-[30px] rounded-lg overflow-visible;
}

// Header modal
.formVendorTitleModal {
  @apply text-20-700-normal mb-[30px] font-inter;
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

// Body modal
.bodyFormVendorModal {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-[20px] pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[20px];
}

.modalContent {
  @apply flex flex-1 flex-col;
}

.inputContent {
  @apply gap-[20px] grid grid-cols-2;
}

.inputContentThree {
  @apply gap-[20px] grid grid-cols-3;
}

.footerFormVendorModal {
  @apply flex items-center justify-between gap-3 pt-[30px];
}

.checkboxSendToQuickBooks {
  @apply flex gap-1 text-12-400-normal text-primary-zinc-500;
}

.leftFooter,
.rightFooter {
  @apply flex items-center gap-3;
}

.changeStatusBtn {
  @apply flex justify-center items-center w-[30px] h-[30px] border border-primary-neutral-400 rounded-[5px];
}

.inactiveBtn {
  @apply hover:bg-primary-neutral-400/5;
}

.activeBtn {
  @apply border-primary-blue-600 hover:bg-primary-blue-600/5;
}
