.dropdownChartContainer {
    @apply relative w-auto;
}

.dropdownChartToggle {
    @apply flex justify-center items-center bg-white w-[92px] cursor-pointer border border-solid gap-2 px-2 h-[36px] rounded-lg border-primary-neutral-200;
}

.iconClass {
    @apply w-3 h-3;
}
  
.dropdownChartToggleText {
    @apply truncate text-primary-zinc-800 text-12-500-15 flex justify-center;
}
  
.dropdownChartList {
    @apply absolute top-[calc(100%+8px)] z-50 bg-white m-0 p-1 list-none border border-solid rounded-lg shadow-md w-[92px];
}
  
.dropdownChartItem {
    @apply p-2 mt-1 text-12-400-15 text-gray-600 rounded-md cursor-pointer truncate;
}
  
  // TODO: Change style baseDropdownItem hover
.dropdownChartItem:hover {
    @apply bg-primary-gray-300 text-primary-zinc-800;
}
  
.dropdownChartItemSelected {
    @apply bg-sky-950  text-primary-zinc-800 bg-opacity-10;
}
  
.dropdownChartNoData {
    @apply text-12-400-15 text-center text-primary-zinc-400 py-[13px];
}