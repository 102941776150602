.modalContainer {
  @apply fixed bg-black top-0 bottom-0 left-0 right-0 z-10 bg-opacity-25 font-inter;
}

.modalContent {
  @apply w-[997px] pt-10 p-6 bg-white border rounded-[5px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] px-10 overflow-visible max-h-[80%] flex flex-col;
}

.modalHead {
  @apply mb-[18px];
}

.modalTitle {
  @apply text-14-500-17 text-primary-neutral-700;
}

.modalBody {
  @apply flex flex-1 flex-col overflow-y-scroll gap-4 pb-2;
}

.noteName {
  @apply w-[325px];
}

.jobSelection {
  @apply w-[325px] mt-4;
}

.modalFooter {
  @apply mt-[22px] flex flex-row gap-4;
}
