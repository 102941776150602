.container {
  @apply relative;
}

.label {
  @apply text-12-400-normal text-primary-neutral-400 mb-[10px] block;
}

.required {
  @apply text-inherit text-primary-rose-500;
}

.searchBox {
  @apply w-full h-[35px] border border-primary-gray-300 rounded-lg flex items-center justify-between overflow-hidden;
}

.inputSearch {
  @apply w-full h-full flex-1 text-12-400-normal text-primary-zinc-500 px-[10px] outline-none;
  @apply disabled:bg-transparent;
}

.toggleOptions {
  @apply px-[10px] h-full;
}

.selectIcon {
  @apply rotate-0 transition-all duration-300;
}

.selectIcon.active {
  @apply rotate-180;
}

.optionList {
  @apply z-50 rounded-lg border border-primary-seashell-100 bg-white p-1;
  @apply [--anchor-max-height:150px];
  @apply transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.option {
  @apply data-[focus]:bg-primary-cyan-600/5 data-[focus]:text-primary-cyan-600 px-3 py-2 text-14-400-normal text-primary-neutral-400 rounded-md cursor-pointer;
}

.noData {
  @apply px-3 py-2 text-14-400-normal text-primary-neutral-400 rounded-md;
}

.option.active {
  @apply text-white bg-primary-cyan-600 data-[focus]:bg-primary-cyan-600 data-[focus]:text-white;
}

.errorMessage {
  @apply mt-1 text-10-400-normal text-primary-rose-500;
}

.loadingBox {
  @apply flex items-center justify-center;
}

.loading {
  @apply w-4 h-4 rounded-full border-[2px] border-t-primary-neutral-400 border-transparent animate-spin;
}

.lastItem {
  @apply h-4 mt-1;
}
