.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[780px] max-h-[80%] bg-white p-[30px] rounded-lg flex flex-col overflow-hidden;
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.title {
  @apply text-20-700-normal text-black truncate pb-5 border-b border-primary-gray-300 mb-[30px];
}

.body {
  @apply flex-1 overflow-y-auto;
}

.body::-webkit-scrollbar {
  @apply w-0;
}

.info {
  @apply mb-6;
}

.infoItem {
  @apply flex items-center gap-4 mb-5;
  @apply last:mb-0;
}

.key {
  @apply w-[150px] text-14-400-normal text-primary-neutral-400 text-wrap;
}

.value {
  @apply flex-1 w-full text-14-400-normal text-primary-zinc-800 text-wrap;
}

.group {
  @apply flex items-center gap-[6px];
}

.comment {
  @apply pt-6 border-t border-t-primary-gray-300;
}

.commentLabel {
  @apply text-17-600-normal text-primary-zinc-800 mb-3;
}

.commentValue {
  @apply text-14-400-24 text-primary-zinc-500;
}

.actions {
  @apply flex items-center justify-end gap-3 mt-[30px];
}
