.container {
  @apply flex gap-5 overflow-hidden;
}

.radioVertical {
  @apply flex-col;
}

.label {
  @apply text-12-400-normal text-primary-zinc-500 truncate text-nowrap capitalize;
}

.radioGroup {
  @apply flex items-center gap-2 cursor-pointer;
}

.radio {
  @apply hidden;
}

.radioMark {
  @apply relative w-3 h-3 rounded-full border border-primary-zinc-400;
}

.radio:checked + .radioMark {
  @apply border-primary-cyan-600;
  @apply after:content-[""] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[6px] after:h-[6px] after:rounded-full after:bg-primary-cyan-600;
}

.optionLabel {
  @apply text-primary-zinc-800 text-12-400-15 select-none;
}

.required {
  @apply text-sunset-orange-500;
}

.errorMessage {
  @apply min-h-4 text-12-500-15 text-primary-red-500 px-2 mt-1 line-clamp-2 break-words;
}
