.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.buttonAction {
  @apply flex items-center justify-end mb-[18px];
}

.statisticTable {
  @apply flex-1 overflow-y-hidden z-0;
}

.paginationTable {
  @apply flex items-center justify-center mt-6;
}

.titleQuickBook {
  @apply flex items-center justify-center;
}

.quickBookWrap {
  @apply flex items-center justify-center;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-3xl;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-3xl;
}

.tooltipWrap {
  @apply z-10 flex flex-1 flex-col;
}

.tooltipText {
  @apply z-10 text-12-500-15 text-gray-400;
}

.filterGroup {
  @apply mb-3 last:mb-0 flex items-center justify-between gap-5 min-w-[300px] h-[31px];
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between w-[200px] overflow-hidden;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}

.avatarFirstLastName {
  @apply flex items-center gap-[10px] text-12-400-normal w-full overflow-hidden;
}

.firstLastName {
  @apply truncate;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between w-[200px] overflow-hidden;
}

.tagAssigneeSelected {
  @apply flex flex-row items-center min-w-[71px] border-[1px] rounded-[100px] h-5 pl-[2px] pr-1 gap-1;
}

.iconCloseAssignee {
  @apply w-[6px] h-[6px] ml-auto shrink-0;
}

.tagFirstName {
  @apply text-12-400-15 truncate;
}
