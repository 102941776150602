.container {
  @apply w-full h-full overflow-y-auto pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.basicInformationProductTabWrap {
  @apply flex flex-col gap-3 w-full;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-full;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-full;
}
