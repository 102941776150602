.dashboardContainer {
  @apply w-full flex h-full flex-col pr-[35px] overflow-hidden;
}

.headerWrap {
  @apply mb-4;
}

.statisticContainer {
  @apply w-full grid grid-cols-4 gap-x-4;
}

.body {
  @apply mt-4 flex-1 w-full flex gap-x-4;
}

.content {
  @apply overflow-y-auto;
}

.chartsContainer {
  // @apply grid grid-rows-6 gap-y-4 w-full;
  @apply w-full grid grid-cols-2 gap-4;
}

.analyticsChartWrap {
  @apply row-span-6;
}

.performanceChartWrap {
  @apply row-span-4;
}

.topTechniciansContainer {
  @apply border basis-[430px] h-[766px] rounded-[4px] py-6 px-5;
}
