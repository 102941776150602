.container {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.pagePagination {
  @apply mt-6;
}

.avatarFirstLastName {
  @apply flex items-center gap-2;
}

.date {
  @apply text-primary-neutral-700 font-inter text-12-400-15;
}

.overdueInvoice {
  @apply text-17-600-20 text-radical-red-500 font-inter;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between w-[200px] overflow-hidden;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}

.filterGroup {
  @apply mb-3 last:mb-0 flex items-center justify-between gap-5 min-w-[350px] h-[31px];
}

.tagFirstName {
  @apply text-12-400-15 truncate;
}

.tagJobName {
  @apply text-12-400-15 truncate pl-[2px];
}

.jobNameWrap {
  @apply flex items-center gap-[10px] text-12-400-normal w-full overflow-hidden;
}

.jobName {
  @apply truncate;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.tagAssigneeSelected {
  @apply flex flex-row items-center min-w-[71px] border-[1px] rounded-[100px] h-5 pl-[2px] pr-1 gap-1;
}

.iconCloseAssignee {
  @apply w-[6px] h-[6px] ml-auto shrink-0;
}
