.messageNotificationsContainer {
  @apply relative;
}

.messageNotificationsContent {
  @apply relative w-10 h-10 rounded-full flex items-center justify-center cursor-pointer;
}

.messageNotificationsNumberWrap {
  @apply absolute w-4 h-4 rounded-full bg-primary-blue-600 -top-[3px] right-0 flex justify-center leading-4;
}

.messageNotificationsNumber {
  @apply text-12-400-15 text-white flex justify-center items-center font-inter;
}
