.container {
  @apply relative;
}

.viewEdit {
  @apply w-[32px] h-[32px] flex items-center justify-center;
  @apply bg-primary-cyan-600 rounded-[100px] border-[2px] border-white;
  @apply absolute bottom-0 right-0 cursor-pointer;
}

.fileInput {
  @apply hidden;
}