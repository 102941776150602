$out-of-range-color: #aeaeb2 !default;
$out-of-range-bg-color: #ffffff !default;

$calendar-border: #e7e7e7 !default;
$cell-border: #e7e7e7 !default;

$border-color: #e7e7e7 !default;

// Each calendar segment is 1/7th.
$segment-width: 0.14286% !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

$event-bg: #ecf6ff !default;
$event-border: #e1e9f0 !default;
$event-outline: #3b99fc !default;
$event-color: #1d0202 !default;
$event-border-radius: 4px !default;
$event-padding: 0px !default;
$event-zindex: 4 !default;

$btn-color: #797d8a !default;
$btn-bg: #ffffff !default;
$btn-border: #ccc !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: rgba(4, 139, 168, 0.03) !default;

@import 'react-big-calendar/lib/sass/styles.scss';

.rbc-calendar {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}

// Month View
.rbc-month-view {
  border-radius: 6px;
}

// Header
.rbc-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  background-color: rgba(212, 223, 227, 0.3);
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.rbc-header:first-child {
  border-top-left-radius: 6px;
}
.rbc-header:last-child {
  border-top-right-radius: 6px;
}

// Cells
.rbc-date-cell {
  padding: 15px 15px 0;
}

// Events
.rbc-calendar .rbc-event {
  background-color: unset;
  padding: 0;
}

.rbc-calendar .rbc-event:focus {
  outline: none;
}

.rbc-calendar .rbc-row-segment {
  padding: 6px;
}

// Week && Day
.rbc-time-view {
  border-radius: 8px;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-time-gutter {
  background-color: rgba(212, 223, 227, 0.3);
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  border-top: unset;
}

.rbc-time-slot {
  display: flex;
  align-items: center;
}

.rbc-label {
  display: inline-block;
  width: 76px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}

.rbc-time-slot:last-child {
  display: none;
}

.rbc-time-gutter > .rbc-timeslot-group {
  width: 76px;
}

.rbc-timeslot-group {
  height: 60px;
  min-height: 60px;
  border-color: #e7e7e7;
}

.rbc-time-view .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  background-color: white;
}

.rbc-events-container .rbc-event-label {
  display: none;
}

.rbc-events-container .rbc-event:focus {
  outline: none;
}
