.originStyle {
  @apply w-full h-full min-h-[31px] flex justify-center items-center py-[6px] px-3 rounded-[5px] bg-opacity-[10%] text-primary-emerald-900 gap-2 text-12-500-15 font-inter;
}

.originStyle.sky500 {
  @apply text-sky-500 bg-sky-500 bg-opacity-[10%];
}

.originStyle.orange400 {
  @apply text-primary-orange-400 bg-primary-orange-400 bg-opacity-[10%];
}

.originStyle.emerald900 {
  @apply text-primary-emerald-900 bg-common-DEF7EC;
}

.originStyle.green600 {
  @apply text-primary-green-600 bg-primary-green-600 bg-opacity-[10%];
}

.originStyle.red600 {
  @apply text-primary-red-600 bg-primary-red-600 bg-opacity-[10%];
}

.originStyle.red800 {
  @apply text-primary-red-800 bg-primary-red-800 bg-opacity-[10%];
}

.originStyle.yellowC6AF40 {
  @apply bg-common-C6AF40 text-common-C6AF40 bg-opacity-[10%];
}
