.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply flex relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[780px] max-h-[80%] bg-white p-[30px] rounded-lg overflow-visible;
}

.iconCloseModal {
  @apply absolute top-3 right-3 cursor-pointer;
}

.modalContent {
  @apply flex flex-1 flex-col;
}

.modalHeader {
  @apply text-20-700-normal mb-[20px] font-inter;
}

.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.modalBody {
  @apply flex flex-col w-full flex-1 overflow-y-scroll gap-[20px] pb-2;
}

.inputContent {
  @apply gap-[20px] grid grid-cols-2;
}

.modalFooter {
  @apply flex gap-3 pt-[30px] justify-end;
}

.formItemTitle {
  @apply text-14-400-normal text-mountain-mist-600;
}
