// Button
.btnGroup {
  @apply flex justify-center items-center focus:outline-none;
}

.btnImg {
  @apply h-[20px] w-[20px];
}

// Popup
.popupContainer {
  @apply bg-white w-[240px] border border-primary-neutral-100 p-[20px] rounded-[8px] shadow-[6px_26px_54px_0_rgba(0,16,19,0.1)];
  @apply transition duration-200 ease-out data-[closed]:scale-90 data-[closed]:opacity-0;
}

.popBtnGroup {
  @apply flex gap-[10px] flex-col;
}

.buttonOption {
  @apply flex gap-2 justify-start items-center w-full h-[29px] p-[6px] rounded-[6px];
}

.defaultBg {
  @apply hover:bg-gray-100;
}

.otherBg {
  @apply hover:bg-primary-rose-50;
}

.labelText {
  @apply text-14-400-17;
}

.defaultColor {
  @apply text-primary-zinc-800;
}

.otherColor {
  @apply text-radical-red-500;
}

// No data
.optionNoData {
  @apply flex justify-center items-center w-full p-[6px] text-12-400-15 text-primary-zinc-500;
}
