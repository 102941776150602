.dateBlock {
  @apply flex flex-col justify-center items-center border-l border-l-primary-neutral-200;
}

.dateText {
  @apply text-14-500-17 text-primary-zinc-800;
}

.dateOfWeek {
  @apply font-normal;
}
