.employeeTimeClockContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.documentContent {
  @apply h-full w-full flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.statisticTable {
  @apply flex-1 flex justify-center items-center overflow-hidden z-0;
}

.textBase {
  @apply text-primary-neutral-700 font-inter text-12-400-15;
}

.avatarFirstLastName,
.date {
  @apply flex items-center gap-2;
}

.pagePagination {
  @apply mt-6;
}

.tableLink {
  @apply text-12-400-normal text-primary-cyan-600 underline;
}
