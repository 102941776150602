.container {
  @apply fixed inset-0 bg-black/25 z-10;
}

.modal {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] max-h-[80%] bg-white p-[30px] rounded-lg flex flex-col;
}

.title {
  @apply text-14-400-normal text-primary-zinc-800 text-center text-wrap;
}

.actions {
  @apply flex items-center justify-end gap-3 mt-[30px];
}
