.adminViewLocationModal {
  @apply fixed top-0 right-0 bottom-0 left-0 z-10 bg-black bg-opacity-20 flex justify-center items-center;
}

.containerViewLocationModal {
  @apply bg-white w-[973px] min-h-[86px] max-h-[80%] border-[5px] overflow-hidden border-white rounded-[12px] relative;
}

.iconClosingViewLocationModal {
  @apply absolute right-[34px] top-[34px] cursor-pointer;
}

.viewMapLocation {
  @apply w-full h-[766px] rounded-[12px] bg-gray-300 overflow-auto;
}
