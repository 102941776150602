.manageVendorsContainer {
  @apply h-full w-full flex flex-col gap-y-4 overflow-hidden pr-[14px] font-inter;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.buttonAction {
  @apply flex items-center justify-end mb-[18px];
}

.statisticTable {
  @apply flex-1 overflow-y-hidden z-0;
}

.paginationTable {
  @apply flex items-center justify-center mt-6;
}

.titleQuickBook {
  @apply flex items-center justify-center;
}

.quickBookWrap {
  @apply flex items-center justify-center;
}

.greenCircle {
  @apply w-4 h-4 bg-green-600 rounded-3xl;
}

.grayCircle {
  @apply w-4 h-4 bg-gray-400 rounded-3xl;
}

.contentFilterWrap {
  @apply flex gap-5 items-center justify-between;
}

.labelFilter {
  @apply h-[31px] flex items-center text-12-400-normal text-primary-zinc-500 capitalize;
}
