.toolbarContainer {
  @apply flex flex-row justify-between items-center;
}

.toolbarContainer.rounded {
  @apply py-3 pr-4 pl-6 border border-primary-gray-300 rounded-xl min-h-[57px];
}

.titleGroup {
  @apply flex items-center gap-4;
}

.title {
  @apply text-17-600-20 text-primary-neutral-700 font-inter;
}

.actions {
  @apply flex flex-row gap-4;
}

.searchGroup {
  @apply relative;
}

.searchIcon {
  @apply size-5 absolute top-1/2 left-2 cursor-pointer -translate-y-1/2;
}

.searchInput {
  @apply w-[350px] h-[31px] size-full pl-9 pr-2 outline-none text-12-500-normal text-primary-zinc-800 truncate;
  @apply border border-primary-gray-300 rounded-[5px];
}
