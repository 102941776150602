.container {
  @apply w-full h-full flex flex-col items-center justify-center;
}

.content {
  @apply w-full;
}

.content {
  @apply flex flex-col;
}

.title {
  @apply relative text-24-700-36 text-primary-zinc-800 font-inter mb-4;
}

.title::after {
  @apply content-[''] absolute top-full left-0 mt-[8px] w-[26px] h-1 bg-primary-cyan-600;
}

.form {
  @apply mb-8;
}

.formItem {
  @apply mb-4;
}

.formItem:last-child {
  @apply mb-0;
}

.createNewPasswordTextNote {
  @apply text-14-400-21 text-black font-poppins;
}

.createNewPasswordFormNote {
  @apply mb-[30px] mt-2 pr-[50px];
}
