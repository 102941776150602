.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.form {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.body {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-5 pr-2 relative;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}

.twoColumns {
  @apply grid grid-cols-2 gap-5;
}

.fieldListProductContent {
  @apply flex flex-col gap-5 mt-10;
}

.fieldListProduct {
  @apply flex flex-col gap-5;
}

.cLabel {
  @apply text-12-400-normal text-primary-zinc-500;
}

.formListProduct {
  @apply flex flex-col gap-y-5;
}

.optionSelect {
  @apply flex items-center justify-between w-full gap-2;
}

.name {
  @apply truncate;
}

.textNoData {
  @apply text-12-400-normal text-neutral-500 w-full text-center;
}

.totalContent {
  @apply flex justify-end;
}

.textTotal {
  @apply text-12-400-normal text-primary-zinc-800;
}
