.container {
  @apply w-full h-full flex flex-col gap-4 pr-4 overflow-hidden;
}

.quickBooks {
  @apply w-[250px];
}

.title {
  @apply text-14-500-normal text-primary-zinc-800 mb-6;
}

.body {
  @apply flex-1 flex flex-col border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.status {
  @apply text-20-700-normal text-primary-zinc-800 mb-6;
}

.switchStatus {
  @apply inline-flex items-center justify-center gap-2 px-3 h-8 bg-primary-cyan-600 rounded-[5px] border border-primary-gray-300 select-none;
  @apply text-white text-12-500-normal;
}

.card {
  @apply w-[311px] min-w-[311px] rounded-lg border border-primary-gray-300 flex flex-col justify-between overflow-hidden;
}

.cardHeader {
  @apply px-[10px] pt-[10px] select-none overflow-hidden;
}

.cardHeaderLogo {
  @apply relative w-full h-[120px] rounded-lg flex items-center justify-center bg-primary-green-650/10 overflow-hidden;
}

.cardHeaderLogoImg {
  @apply w-[175px];
}

.accountant {
  @apply absolute right-1 top-1 w-6 h-6 cursor-pointer;
}

.cardBody {
  @apply flex-1 p-5;
}

.cardTitle {
  @apply text-18-400-normal text-primary-zinc-800 mb-3;
}

.cardDesc {
  @apply text-14-400-21 text-primary-zinc-800;
}

.cardActions {
  @apply px-5 pb-5;
}

.cardActionsItem {
  @apply w-full h-9 flex items-center justify-center rounded-lg px-3 border border-primary-cyan-600 select-none outline-none cursor-pointer;
  @apply text-14-400-normal text-primary-cyan-600;
  @apply transition ease-in-out duration-300;

  &:hover {
    @apply bg-primary-cyan-600 text-white;
  }
}

.cardActionsItem.connecting {
  @apply pointer-events-none;
}

.cardActionsItem.disconnected {
  @apply text-radical-red-500 border-radical-red-500/20;

  &:hover {
    @apply bg-rose-50 border-rose-50;
  }
}

.tooltipWrap {
  @apply text-12-500-15 text-white bg-black rounded-[6px] !important;
}
