.container {
  @apply w-full h-full flex flex-col gap-y-4 pr-[14px] font-inter overflow-hidden;
}

.body {
  @apply flex border border-primary-gray-300 rounded-lg p-6 overflow-hidden;
}

.contentWrap::-webkit-scrollbar {
  @apply w-0;
}

.contentWrap {
  @apply flex w-full h-full overflow-y-auto gap-6 pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.timeClockDetailsSection {
  @apply basis-1/2 mb-6 gap-5;
}

.timeClockDetailsTitle {
  @apply text-14-500-normal text-primary-zinc-800 mb-2;
}

.timeClockDetails {
  @apply flex flex-col gap-4;
}

.avatarGroup {
  @apply flex items-center gap-2;
}

.timeClockDetailsItem {
  @apply w-full flex items-start gap-2 mb-5 last:mb-0;
}

.key {
  @apply text-14-400-normal text-primary-neutral-400 last:mb-0 text-wrap;
}

.value {
  @apply text-14-400-normal text-primary-zinc-800 last:mb-0 text-wrap;
}

.mapSection {
  @apply basis-1/2;
}

.map {
  @apply h-[522px] rounded-xl overflow-auto border border-primary-gray-300 p-[10px];
}
