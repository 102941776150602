.baseInputLabel {
  @apply block pb-2 text-12-400-normal text-primary-zinc-500 truncate;
}

.baseInputLabelRequired {
  @apply text-primary-rose-500;
}

.baseInputContent {
  @apply bg-white flex justify-center items-center outline-0 px-2 rounded-[5px] border border-primary-gray-300 w-full;
}

.baseInputPrefix {
  @apply flex justify-center items-center pr-1;
}

.baseInputSuffix {
  @apply flex justify-center items-center pl-1;
}

.baseInputBase {
  @apply outline-0 text-primary-zinc-900 text-12-400-15 py-3 rounded-lg w-full font-inter;
}

.baseInputBase::placeholder {
  @apply text-primary-zinc-500;
}

.baseInputBase:disabled {
  @apply bg-transparent;
}

.baseInputMessageError {
  @apply min-h-4 px-2 text-primary-red-500 text-12-400-15 left-0 top-full mt-1;
}

.baseInputSuffixImg {
  @apply w-[22px] h-[22px] cursor-pointer mr-5;
}

// Custom Input Auth
.baseInputContent.auth {
  @apply bg-white flex justify-center items-center outline-0 rounded-lg border border-primary-gray-300 w-full px-0;
}

.baseInputBase.auth {
  @apply outline-0 text-primary-zinc-900 text-14-500-17 py-3 rounded-lg w-full px-5;
}

.baseInputBase.auth::placeholder {
  @apply text-14-400-17 truncate text-primary-zinc-400;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  @apply appearance-none;
}
