.baseTableComponent {
  @apply w-full h-full flex-1 bg-white overflow-auto relative overflow-y-scroll;
}

.tableContainer {
  @apply w-full text-left table-auto;
}

.colTable {
  @apply py-4 text-left text-14-400-17 text-zinc-800 font-inter cursor-pointer z-10 sticky top-0 bg-white border-transparent pl-8 border-0 bg-none;
}

.colTable::after {
  @apply content-[""] absolute left-0 bottom-0 w-full border-b-[1px] border-b-primary-gray-300 h-full border-0 bg-primary-gray-300 bg-opacity-[35%] px-0 -z-10;
}

.colTableBody {
  @apply text-12-400-15 text-primary-zinc-900 cursor-pointer break-words whitespace-normal py-[17px] pl-[32px]  border-b bg-primary-gray-300 bg-opacity-[10%] truncate pr-2;
}

.rowTableBody {
  @apply hover:bg-primary-gray-300/15;
}

.thead {
  @apply border-b-0;
}

.colTable:first-child::after {
  @apply rounded-tl-[5px];
}

.colTable:last-child::after {
  @apply rounded-tr-[5px];
}

.noDataAvailable {
  @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
}
