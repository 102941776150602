.line {
  @apply w-full bg-gray-300 h-[1px] mb-[30px];
}

.form {
  @apply w-full h-full flex flex-col overflow-hidden;
}

.body {
  @apply flex flex-col w-full flex-1 overflow-y-auto gap-5 pr-2 relative;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.actions {
  @apply flex gap-3 pt-[30px] justify-end;
}

.twoColumns {
  @apply grid grid-cols-2 gap-5;
}

.titleLine {
  @apply flex flex-col gap-5;
}

.lineHr {
  @apply w-full bg-gray-300 h-[1px];
}

.cLabel {
  @apply text-14-500-normal text-primary-zinc-800;
}

.formListGroup {
  @apply flex flex-col gap-y-5;
}

.iconAddField {
  @apply object-cover;
}

.btnRemoveField,
.iconAddFile,
.iconAddField {
  @apply w-4 h-4;
}

.btnAddWrap {
  @apply flex items-center gap-x-5;
}

.btnAddField {
  @apply flex items-center gap-x-1;
}

.btnTextAdd {
  @apply text-12-500-normal text-primary-cyan-600;
  @apply group-hover:opacity-80;
}

.buttonClose {
  @apply mt-6;
}

.avatarContent {
  @apply flex justify-center;
}

.quickBooks {
  @apply absolute top-0 right-2 flex items-center justify-end;
}

.quickBooksLabel {
  @apply text-12-400-normal text-primary-zinc-500;
}

.quickBooksStatus {
  @apply w-4 h-4 rounded-full bg-gray-400 ml-2;
}

.quickBooksStatus.active {
  @apply bg-green-600;
}
