.modal {
  @apply flex flex-1 flex-col overflow-hidden;
}

.title {
  @apply text-20-700-normal text-black truncate border-b pb-4;
  border-color: rgba(9, 9, 11, 0.1);
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.body {
  @apply flex w-full overflow-y-auto mt-4 gap-[30px] pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-full bg-primary-slate-300;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-primary-slate-100;
  }
}

.viewInfo {
  @apply flex flex-col gap-3 flex-grow max-w-[calc(100%-205px)];
}

.viewAvatar {
  @apply flex w-[175px] h-[175px] items-center justify-end;
}

.avatarGroup {
  @apply flex gap-[6px] items-center;
}
