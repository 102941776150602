.bodyContainer {
  @apply h-full flex flex-col overflow-y-auto mb-4;
}

.closeIcon {
  @apply absolute top-3 right-3 cursor-pointer;
}

.title {
  @apply text-20-700-normal text-black truncate pb-5 border-b border-primary-gray-300 mb-[30px];
}

.body {
  @apply mb-[30px] grid grid-cols-2 gap-5 mt-[20px];
}

.actions {
  @apply flex items-center justify-end gap-3;
}

.formDocument {
  @apply h-full flex flex-col overflow-hidden;
}
